import React, { FC } from 'react';
import { QualityIcon, TestingIcon, View } from '../../../lib';
import { ExperienceList } from '../../components';
import { useLocale } from '../../../global';
import { Numbers } from '../../../utils';

import strings from './CareerView.strings.json';

export interface CareerViewProps {
	/* TODO */
}

export const CareerView: FC<CareerViewProps> = () => {
	const { locale } = useLocale();

	const age = Math.floor(
		(Date.now() - Number(new Date('06-13-1989'))) / 3.154e10,
	);

	const yearsOfExp =
		Math.floor((Date.now() - Number(new Date('08-16-2015'))) / 3.154e10) + 2;

	return (
		<View>
			<div className="col-span-2" />
			<div className="col-span-8">
				<section className="mt-16">
					<h1 className="text-3xl text-app-primary">
						{(strings.overview as Record<string, string>)[locale]}
					</h1>
					<div className="mt-8 flex">
						<img
							className="inline-flex h-auto rounded-full"
							src="https://via.placeholder.com/250"
							alt="placeholder"
						/>
						<div className="ml-12 w-full">
							<h3 className="text-2xl">
								<strong>
									{(strings.title as Record<string, string>)[locale]}
								</strong>
								: {(strings.titleIs as Record<string, string>)[locale]}
							</h3>
							<h3 className="text-2xl mt-2">
								<strong>
									{(strings.company as Record<string, string>)[locale]}
								</strong>
								: {(strings.companyIs as Record<string, string>)[locale]}
							</h3>
							<h3 className="text-2xl mt-2">
								<strong>
									{(strings.age as Record<string, string>)[locale]}
								</strong>
								:{' '}
								{age <= 125
									? (Numbers as Record<string, Record<string, string>>)[
											String(age)
									  ][locale]
									: 'Deceased'}
							</h3>
							<div className="flex py-16 justify-evenly">
								<div className="relative flex justify-center">
									<QualityIcon className="absolute -top-2 w-28 opacity-10" />
									<div className="flex flex-col absolute top-0 w-28">
										<strong className="w-full text-center text-5xl">
											{
												(Numbers as Record<string, Record<string, string>>)[
													String(yearsOfExp)
												][locale]
											}
										</strong>
										<span className="text-center">
											{(strings.badge1line1 as Record<string, string>)[locale]}
											<br />
											{(strings.badge1line2 as Record<string, string>)[locale]}
										</span>
									</div>
								</div>
								<div className="relative flex justify-center">
									<TestingIcon className="absolute -top-2 w-28 opacity-10" />
									<div className="flex flex-col absolute top-0 w-28">
										<strong className="w-full text-center text-5xl">
											{
												(Numbers as Record<string, Record<string, string>>)[
													'4'
												][locale]
											}
											%
										</strong>
										<span className="w-full text-center">
											{(strings.badge2line1 as Record<string, string>)[locale]}
											<br />{' '}
											{(strings.badge2line2 as Record<string, string>)[locale]}
										</span>
									</div>
								</div>
								<div className="relative flex justify-center">
									<TestingIcon className="absolute -top-2 w-28 opacity-10" />
									<div className="flex flex-col absolute top-0 w-28">
										<strong className="w-full text-center text-5xl">
											{
												(Numbers as Record<string, Record<string, string>>)[
													'8'
												][locale]
											}
											%
										</strong>
										<span className="w-full text-center">
											{(strings.badge3line1 as Record<string, string>)[locale]}
											<br />{' '}
											{(strings.badge3line2 as Record<string, string>)[locale]}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="mt-16">
					<ExperienceList />
				</section>
			</div>
			<div className="col-span-2" />
		</View>
	);
};
