import React, { FC } from 'react';
import styled from 'styled-components';
import {
	LoadingContent as Content,
	LoadingMessages as Messages,
	LoadingOverlay as Overlay,
	LoadingProgress as Progress,
} from './components';
import { LoadingProvider } from './providers';

export interface LoadingProps {
	//
}

const messages = [
	{
		message: 'Initializing Service Worker',
		key: 'MSG_1',
	},
	{
		message: 'Fetching Media Assets',
		key: 'MSG_2',
	},
	{
		message: 'Optimizing Render Cache',
		key: 'MSG_3',
	},
	{
		message: 'Analyzing User Preferences',
		key: 'MSG_4',
	},
	{
		message: 'Preparing Workspace',
		key: 'MSG_5',
	},
];

export const Loading: FC<LoadingProps> = () => {
	return (
		<LoadingProvider>
			<Overlay>
				<Content>
					<ImageContainer>
						<Image src="/crest.png" alt="Rob Hameetman <robhameetman.com>" />
					</ImageContainer>
					<Progress />
					<SmallText>
						<Messages messages={messages} />
					</SmallText>
				</Content>
			</Overlay>
		</LoadingProvider>
	);
};

const ImageContainer = styled.div`
	position: relative;
	display: inline-flex;
	width: 100%;
	align-self: flex-start;
	align-items: center;
	justify-content: center;
`;

const Image = styled.img`
	width: 2rem;
`;

const SmallText = styled.div`
	display: inline-flex;
	color: #2e3137;
	font-size: 0.8rem;
	font-weight: 700;
	width: 100%;
	text-align: center;
	align-items: space-around;
	justify-content: center;
	padding: 0.8em 0 0 0;
`;
