import React, { FC } from 'react';
import styled, { keyframes } from 'styled-components';

const CURSOR = '_';

export interface CursorProps {}

export const Cursor: FC<CursorProps> = () => {
	return <CursorText>{CURSOR}</CursorText>;
};

const Blink = keyframes`
  from, to {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
`;

const CursorText = styled.span`
	font-weight: 700;
	color: white;
	font-size: 1em;
	padding-left: 2px;
	animation: ${Blink} 1s step-end infinite;
`;
