import { createContext } from 'react';

export interface LoadingState {
  fin: boolean;
  progress: number;
  addToProgress: (value: number) => void;
  end: () => void;
}

export const LoadingContext = createContext<LoadingState>({
  fin: false,
  progress: 0,
  addToProgress: (value: number) => {},
  end: () => {},
});
