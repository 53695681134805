import React, { FC, useEffect, useRef } from 'react';
import { extend, useFrame, useThree } from 'react-three-fiber';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass';
import { VolumetricLightShader } from '../../shaders';
// import { GlitchPass } from './post/Glitchpass'

extend({
	EffectComposer,
	ShaderPass,
	BokehPass,
	RenderPass,
	FilmPass,
	// GlitchPass,
});

interface VolumetricAmbianceProps {
	readonly down: boolean;
}

export const VolumetricAmbiance: FC<VolumetricAmbianceProps> = ({ down }) => {
	const composer = useRef<EffectComposer>();
	const { scene, gl, size, camera } = useThree();

	useEffect(() => void composer!.current!.setSize(size.width, size.height), [
		size,
	]);

	useFrame(() => composer!.current!.render(), 1);

	return (
		<>
			// @ts-ignore
			<effectComposer ref={composer} args={[gl]}>
				// @ts-ignore
				<renderPass attachArray="passes" scene={scene} camera={camera} />
				// @ts-ignore
				<shaderPass attachArray="passes" args={[VolumetricLightShader]} />
				// @ts-ignore
				{/* <bokehPass attachArray="passes" args={[scene, camera, depth]} /> */}
				// @ts-ignore
				<filmPass
					attachArray="passes"
					args={[0.12, 0.025, 648, 0]}
					renderToScreen
				/>
				{/* <glitchPass attachArray="passes" factor={down ? 1 : 0} /> */}
				// @ts-ignore
			</effectComposer>
		</>
	);
};
