import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface ModelingIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const ModelingIcon: FC<ModelingIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 103.97 104.53"
			>
				<path
					d="M13.62,21.64A7.73,7.73,0,0,1,5.9,13.92a1.5,1.5,0,0,1,3,0,4.73,4.73,0,0,0,4.72,4.72,1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M51.83,59.4a7.72,7.72,0,0,1-7.71-7.71,1.5,1.5,0,0,1,3,0,4.71,4.71,0,0,0,4.71,4.71,1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M91.18,20.5a7.72,7.72,0,0,1-7.71-7.72,1.5,1.5,0,0,1,3,0,4.72,4.72,0,0,0,4.71,4.72,1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M12.78,99.46a7.72,7.72,0,0,1-7.71-7.72,1.5,1.5,0,0,1,3,0,4.72,4.72,0,0,0,4.71,4.72,1.5,1.5,0,1,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M90.35,98.32a7.72,7.72,0,0,1-7.72-7.72,1.5,1.5,0,0,1,3,0,4.73,4.73,0,0,0,4.72,4.72,1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M79.12,93.24h-55a1.5,1.5,0,0,1-1.5-1.5,9.77,9.77,0,0,0-6.76-9.3A9.89,9.89,0,0,0,11.93,82a1.47,1.47,0,0,1-1.15-.38,1.51,1.51,0,0,1-.48-1.11V25.05A1.49,1.49,0,0,1,12,23.57a10.37,10.37,0,0,0,1.58.14,9.76,9.76,0,0,0,9.57-11.77,1.52,1.52,0,0,1,1.47-1.81H80a1.51,1.51,0,0,1,1.49,1.66c0,.32,0,.66,0,1a9.8,9.8,0,0,0,9.78,9.79l.36,0,.27,0a1.49,1.49,0,0,1,1.12.41A1.51,1.51,0,0,1,93.41,24V79.44a1.5,1.5,0,0,1-1.71,1.48,9.45,9.45,0,0,0-1.35-.1,9.79,9.79,0,0,0-9.78,9.78,8,8,0,0,0,.05,1,1.49,1.49,0,0,1-1.5,1.65Zm-53.64-3H77.57A12.76,12.76,0,0,1,90.41,77.82V25.54a12.79,12.79,0,0,1-12-12.41h-52c0,.27,0,.53,0,.79a12.79,12.79,0,0,1-6.7,11.25,12.54,12.54,0,0,1-6.4,1.53V79a13.1,13.1,0,0,1,3.44.62A12.74,12.74,0,0,1,25.48,90.24Z"
					fill={secondaryColor}
				/>
				<path
					d="M13.62,26.71a12.76,12.76,0,0,1-2.06-.18,12.78,12.78,0,1,1,14.57-15.2h0a13,13,0,0,1,.27,2.59A12.78,12.78,0,0,1,13.62,26.71Zm0-22.57A9.78,9.78,0,0,0,12,23.57a10.37,10.37,0,0,0,1.58.14,9.76,9.76,0,0,0,9.57-11.77h0a9.76,9.76,0,0,0-9.57-7.8Z"
					fill={primaryColor}
				/>
				<path
					d="M91.18,25.57A12.81,12.81,0,0,1,78.4,12.78a11.2,11.2,0,0,1,.07-1.3A12.78,12.78,0,1,1,97.26,24,12.59,12.59,0,0,1,92,25.52h-.21A6,6,0,0,1,91.18,25.57ZM91.18,3a9.75,9.75,0,0,0-9.73,8.79c0,.32,0,.66,0,1a9.8,9.8,0,0,0,9.78,9.79l.36,0,.27,0a9.58,9.58,0,0,0,4-1.13A9.78,9.78,0,0,0,94.21,3.48h0A9.75,9.75,0,0,0,91.18,3Z"
					fill={primaryColor}
				/>
				<path
					d="M12.78,104.53A12.8,12.8,0,1,1,18.86,103,12.8,12.8,0,0,1,12.78,104.53Zm0-22.57c-.29,0-.57,0-.85,0a9.78,9.78,0,1,0,3.88.44h0A9.75,9.75,0,0,0,12.78,82Z"
					fill={primaryColor}
				/>
				<path
					d="M90.35,103.39a12.74,12.74,0,0,1-12.72-11.5q-.06-.63-.06-1.29A12.79,12.79,0,0,1,90.35,77.82a12.2,12.2,0,0,1,1.77.13,14,14,0,0,1,2.19.5h0a12.79,12.79,0,0,1,2.12,23.4A12.88,12.88,0,0,1,90.35,103.39Zm0-22.57a9.79,9.79,0,0,0-9.78,9.78,8,8,0,0,0,.05,1A9.78,9.78,0,1,0,93.38,81.3h0a9.81,9.81,0,0,0-1.68-.38A9.45,9.45,0,0,0,90.35,80.82Z"
					fill={primaryColor}
				/>
				<path
					d="M91.91,53.37H63.51a1.5,1.5,0,0,1,0-3h28.4a1.5,1.5,0,0,1,0,3Z"
					fill={secondaryColor}
				/>
				<path
					d="M39.82,53.37h-28a1.5,1.5,0,0,1,0-3h28a1.5,1.5,0,0,1,0,3Z"
					fill={secondaryColor}
				/>
				<path
					d="M51.57,93.24a1.5,1.5,0,0,1-1.5-1.5V63a1.5,1.5,0,0,1,3,0V91.74A1.5,1.5,0,0,1,51.57,93.24Z"
					fill={secondaryColor}
				/>
				<path
					d="M51.57,41.91a1.5,1.5,0,0,1-1.5-1.5V11.63a1.5,1.5,0,0,1,3,0V40.41A1.51,1.51,0,0,1,51.57,41.91Z"
					fill={secondaryColor}
				/>
				<path
					d="M51.83,64.47a12.77,12.77,0,1,1,6.08-1.53A12.79,12.79,0,0,1,51.83,64.47Zm0-22.56a9.79,9.79,0,1,0,3,.48h0A9.75,9.75,0,0,0,51.83,41.91Z"
					fill={primaryColor}
				/>
			</svg>
		);
	},
);
