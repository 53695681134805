import React, { FC } from 'react';
import styled from 'styled-components';

export interface EmailIconProps {}

export const EmailIcon: FC<EmailIconProps> = () => {
	return <Icon className="fas fa-envelope-square" />;
};

const Icon = styled.i``;
