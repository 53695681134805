import React, { Children, FC, MouseEvent, useCallback, useState } from 'react';
import { getCached, setCached } from '../../../utils';

export interface ExperienceListItemProps {
	readonly company: string;
	readonly expanded?: boolean;
	readonly id: string;
	readonly position: string;
}

export const ExperienceListItem: FC<ExperienceListItemProps> = ({
	children,
	company,
	expanded: _expanded = false,
	id,
	position,
}) => {
	const [expanded, setExpanded] = useState(
		_expanded ||
			(getCached<string>('[@com.robhameetman]: Career Sections') ?? '')
				.split(',')
				.includes(id),
	);

	const hasChildren = Boolean(Children.count(children));

	const toggleSection = useCallback(
		(e: MouseEvent<HTMLDivElement>) => {
			if (hasChildren) {
				const expandedSections =
					getCached<string>('[@com.robhameetman]: Career Sections') ?? '';

				const ids = expandedSections.split(',');

				if (ids.includes(id)) {
					setExpanded(false);
					setCached(
						'[@com.robhameetman]: Career Sections',
						ids.filter((currentId) => currentId !== id).join(','),
					);
				} else {
					setExpanded(true);
					setCached(
						'[@com.robhameetman]: Career Sections',
						[...ids, id].join(','),
					);
				}
			}
		},
		[hasChildren, id],
	);

	return (
		<div className="grid grid-cols-12 my-28">
			<div className="col-span-1 flex justify-end">
				<span className="w-1 h-full bg-app-primary inline-block" />
			</div>
			<div
				id={id}
				className={`col-span-11 grid grid-cols-12 py-4 rounded-r-md transition-all ${
					hasChildren
						? 'cursor-pointer hover:bg-app-secondary dark:hover:bg-app-gunmetal'
						: ''
				}`}
				onClick={toggleSection}
			>
				<div className="col-span-1 flex justify-center items-center">
					<img
						className="h-auto rounded-full"
						src="https://via.placeholder.com/62"
						alt="placeholder"
					/>
				</div>
				<div className="col-span-10 text-2xl text-app-primary">
					<span>{position}</span>
					<div className="text-gray-400 text-lg">{company}</div>
				</div>
				<div className="col-span-1 flex items-center justify-center">
					{hasChildren ? (
						<div
							className={`transform transition-transform ${
								expanded ? '-rotate-90' : ''
							}`}
						>
							◀︎
						</div>
					) : null}
				</div>
				{expanded ? (
					<div className="py-4 col-span-12 grid grid-cols-12">{children}</div>
				) : null}
			</div>
		</div>
	);
};
