import React, { FC } from 'react';
import { View } from '../../../lib';

export interface ProjectListViewProps {
	/* TODO */
}

export const ProjectListView: FC<ProjectListViewProps> = () => {
	return <View>{/* TODO */}</View>;
};
