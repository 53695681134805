import React, { FC, useEffect, useRef, useState } from 'react';
import convert from 'color-convert';
import { Color, Uncharted2ToneMapping, sRGBEncoding } from 'three';
import { Crimson, Grey, RhTheme, Slate } from '@com.robhameetman/design';
import { Canvas } from 'react-three-fiber';
import { useTheme } from 'styled-components';
import { VolumetricAmbiance } from '../../effects';
import { Dust, Sparks } from '../../geometry';
import { useViewport } from '../../../../../utils';

export interface PlanarVolumetricTranslationProps {}

export const PlanarVolumetricTranslation: FC<PlanarVolumetricTranslationProps> = (
	props,
) => {
	const { appPrimaryColor } = useTheme() as RhTheme;
	const [down] = useState(false);
	const mouse = useRef([-500, 500]);
	const { width } = useViewport();
	const $initialWidth = useRef(width);
	const [chromaticOffset, setChromaticOffset] = useState(0);

	const [primaryColorHex, setPrimaryColorHex] = useState(
		appPrimaryColor || Crimson[200],
	);

	const [primaryColorHsl, setPrimaryColorHsl] = useState(
		convert.hex.hsl(primaryColorHex),
	);

	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

	useEffect(() => {
		const initialWidth = $initialWidth.current;

		setChromaticOffset(((width - initialWidth) / 100) % 360);
	}, [width]);

	useEffect(() => {
		setPrimaryColorHsl(([h, s, l]) => [h + chromaticOffset, s, l]);
	}, [chromaticOffset, setPrimaryColorHsl]);

	useEffect(() => {
		setPrimaryColorHex(`#${convert.hsl.hex(primaryColorHsl)}`);
	}, [primaryColorHsl]);

	return (
		<Canvas
			pixelRatio={Math.min(2, isMobile ? window.devicePixelRatio : 1)}
			camera={{
				position: [8, 6, 8],
				near: 5,
				fov: 30,
				focus: 100,
				zoom: 1,
			}}
			onCreated={({ gl }) => {
				gl.toneMapping = Uncharted2ToneMapping;
				gl.outputEncoding = sRGBEncoding;
				gl.setClearColor(new Color(Slate[500]));
			}}
		>
			// @ts-ignore
			<fog attach="fog" args={[Slate[200], 4, 240]} />
			<ambientLight
				intensity={2}
				color={new Color(primaryColorHex).lerp(new Color(Grey[300]), 0.25)}
			/>
			<pointLight position={[0, 60, -100]} intensity={1} />
			<pointLight position={[-50, 0, -50]} intensity={1} />
			<spotLight
				castShadow
				intensity={4}
				angle={Math.PI / 10}
				position={[10, 10, 10]}
				shadow-mapSize-width={2048}
				shadow-mapSize-height={2048}
			/>
			<Sparks
				count={200}
				mouse={mouse}
				primaryColor={new Color(primaryColorHex)}
				otherColors={[
					new Color('#13663A'),
					new Color('#9F02CF'),
					new Color('#264F91'),
					new Color('#895500'),
				]}
			/>
			<Dust
				count={isMobile ? 5000 : 10000}
				mouse={mouse}
				color={new Color(appPrimaryColor)}
			/>
			<VolumetricAmbiance down={down} />
		</Canvas>
	);
};
