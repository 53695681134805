import React, { FC } from 'react';
import {
	EngineeringSidebar,
	EngineeringSidebarItem,
	SubdomainDetails,
	SubdomainList,
	SubdomainListItem,
} from '../../components';
import {
	AlgorithmIcon,
	ApiIcon,
	ModelingIcon,
	ServerIcon,
	View,
} from '../../../lib';
import { useRouter } from '../../../routes';

export interface EngineeringViewProps {
	readonly disabled?: boolean;
	readonly expanded?: boolean;
}

export const EngineeringView: FC<EngineeringViewProps> = ({
	expanded: _expanded = false,
}) => {
	const { params, replace } = useRouter();
	const { domain } = params;

	if (!domain) {
		replace('/engineering/languages-and-frameworks');
	}

	const isLanguagesTab = domain === 'languages-and-frameworks';
	const isPlatformsTab = domain === 'platforms';
	const isTechniquesTab = domain === 'techniques';
	const isToolsTab = domain === 'tools';

	return (
		<View>
			<div className="col-span-12 h-full flex">
				<EngineeringSidebar>
					<EngineeringSidebarItem
						icon={ModelingIcon}
						selected={isLanguagesTab}
						domain="languages-and-frameworks"
					>
						Languages/Frameworks
					</EngineeringSidebarItem>
					<EngineeringSidebarItem
						icon={ApiIcon}
						selected={isPlatformsTab}
						domain="platforms"
					>
						Platforms
					</EngineeringSidebarItem>
					<EngineeringSidebarItem
						icon={AlgorithmIcon}
						selected={isTechniquesTab}
						domain="techniques"
					>
						Techniques
					</EngineeringSidebarItem>
					<EngineeringSidebarItem
						icon={ServerIcon}
						selected={isToolsTab}
						domain="tools"
					>
						Tools
					</EngineeringSidebarItem>
				</EngineeringSidebar>
				<SubdomainList>
					<SubdomainListItem subdomain="react">React</SubdomainListItem>
					<SubdomainListItem subdomain="angular">Angular</SubdomainListItem>
					<SubdomainListItem subdomain="vue">Vue</SubdomainListItem>
				</SubdomainList>
				<SubdomainDetails />
			</div>
		</View>
	);
};
