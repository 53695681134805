import React, { FC } from 'react';
import styled from 'styled-components';

export interface MediumIconProps {}

export const MediumIcon: FC<MediumIconProps> = () => {
	return <Icon className="fab fa-medium" />;
};

const Icon = styled.i``;
