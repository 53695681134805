import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface AlgorithmIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const AlgorithmIcon: FC<AlgorithmIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 104.58 104.12"
			>
				<path
					d="M52,57.4h0a1.49,1.49,0,0,1-1.29-.73l-3.28-5.51A1.5,1.5,0,0,1,50,49.63L52,53l3.81-6.39a1.5,1.5,0,0,1,2.58,1.54l-5.11,8.54A1.48,1.48,0,0,1,52,57.4Z"
					fill={primaryColor}
				/>
				<path
					d="M63.6,65H41.38a1.5,1.5,0,0,1-1.5-1.5V41.29a1.5,1.5,0,0,1,1.5-1.5H63.6a1.5,1.5,0,0,1,1.5,1.5V63.51A1.5,1.5,0,0,1,63.6,65ZM42.88,62H62.1V42.79H42.88Z"
					fill={primaryColor}
				/>
				<path
					d="M76.26,29.77a1.47,1.47,0,0,1-1.06-.44,1.5,1.5,0,0,1,0-2.12l10-10a1.5,1.5,0,1,1,2.12,2.12l-10,10A1.47,1.47,0,0,1,76.26,29.77Z"
					fill={primaryColor}
				/>
				<path
					d="M86.19,87.69a1.49,1.49,0,0,1-1-.44l-10-10a1.5,1.5,0,1,1,2.11-2.13l10,10a1.51,1.51,0,0,1,0,2.13A1.53,1.53,0,0,1,86.19,87.69Z"
					fill={primaryColor}
				/>
				<path
					d="M28.16,29.77a1.45,1.45,0,0,1-1.06-.44l-10-10a1.5,1.5,0,0,1,2.12-2.12l10,10a1.5,1.5,0,0,1,0,2.12A1.47,1.47,0,0,1,28.16,29.77Z"
					fill={primaryColor}
				/>
				<path
					d="M18.22,87.69a1.5,1.5,0,0,1-1-2.57l10-10a1.5,1.5,0,1,1,2.12,2.13l-10,10A1.49,1.49,0,0,1,18.22,87.69Z"
					fill={primaryColor}
				/>
				<path
					d="M52.29,29.77a1.5,1.5,0,0,1-1.5-1.5V16.39a1.5,1.5,0,0,1,3,0V28.27A1.5,1.5,0,0,1,52.29,29.77Z"
					fill={primaryColor}
				/>
				<path
					d="M52.29,89.92a1.5,1.5,0,0,1-1.5-1.5V76.54a1.5,1.5,0,0,1,3,0V88.42A1.5,1.5,0,0,1,52.29,89.92Z"
					fill={primaryColor}
				/>
				<path
					d="M88.26,54H76.38a1.5,1.5,0,0,1,0-3H88.26a1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M28.11,54H16.23a1.5,1.5,0,1,1,0-3H28.11a1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M75.88,77.44H28.78a1.5,1.5,0,0,1-1.5-1.5V28.85a1.5,1.5,0,0,1,1.5-1.5h47.1a1.5,1.5,0,0,1,1.5,1.5V75.94A1.5,1.5,0,0,1,75.88,77.44Zm-45.6-3h44.1V30.35H30.28Z"
					fill={secondaryColor}
				/>
				<path
					d="M18.29,19.79H1.5A1.5,1.5,0,0,1,0,18.29V1.5A1.5,1.5,0,0,1,1.5,0H18.29a1.5,1.5,0,0,1,1.5,1.5V18.29A1.5,1.5,0,0,1,18.29,19.79ZM3,16.79H16.79V3H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M103.07,19.79H86.28a1.5,1.5,0,0,1-1.5-1.5V1.5A1.5,1.5,0,0,1,86.28,0h16.79a1.5,1.5,0,0,1,1.5,1.5V18.29A1.5,1.5,0,0,1,103.07,19.79Zm-15.29-3h13.79V3H87.78Z"
					fill={secondaryColor}
				/>
				<path
					d="M18.3,104.12H1.51a1.5,1.5,0,0,1-1.5-1.5V85.83a1.5,1.5,0,0,1,1.5-1.5H18.3a1.5,1.5,0,0,1,1.5,1.5v16.79A1.5,1.5,0,0,1,18.3,104.12ZM3,101.12H16.8V87.33H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M103.08,104.12H86.29a1.5,1.5,0,0,1-1.5-1.5V85.83a1.5,1.5,0,0,1,1.5-1.5h16.79a1.5,1.5,0,0,1,1.5,1.5v16.79A1.5,1.5,0,0,1,103.08,104.12Zm-15.29-3h13.79V87.33H87.79Z"
					fill={secondaryColor}
				/>
				<path
					d="M52.24,16.79a6.9,6.9,0,1,1,6.9-6.89A6.9,6.9,0,0,1,52.24,16.79ZM52.24,6a3.9,3.9,0,1,0,3.9,3.9A3.9,3.9,0,0,0,52.24,6Z"
					fill={secondaryColor}
				/>
				<path
					d="M52.24,101.82a6.9,6.9,0,1,1,6.9-6.9A6.91,6.91,0,0,1,52.24,101.82Zm0-10.8a3.9,3.9,0,1,0,3.9,3.9A3.9,3.9,0,0,0,52.24,91Z"
					fill={secondaryColor}
				/>
				<path
					d="M94.75,59.31a6.9,6.9,0,1,1,6.9-6.9A6.91,6.91,0,0,1,94.75,59.31Zm0-10.8a3.9,3.9,0,1,0,3.9,3.9A3.9,3.9,0,0,0,94.75,48.51Z"
					fill={secondaryColor}
				/>
				<path
					d="M9.73,59.31a6.9,6.9,0,1,1,6.9-6.9A6.91,6.91,0,0,1,9.73,59.31Zm0-10.8a3.9,3.9,0,1,0,3.9,3.9A3.91,3.91,0,0,0,9.73,48.51Z"
					fill={secondaryColor}
				/>
			</svg>
		);
	},
);
