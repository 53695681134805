import React, { FC, MouseEvent } from 'react';
import { Slate } from '@com.robhameetman/design';
import styled from 'styled-components';
import { Bar } from '../Bar';
import { Logo } from '../../logo';
import {
	ApplicationIcon,
	ProgrammingIcon,
	ProgrammingOnScreenIcon,
} from '../../icons';
import { useLocale } from '../../../global';
import { useRouter } from '../../../routes';
import { useAppTheme } from '../../../utils';

import strings from './Desktop.strings.json';
import { LanguageSelect, ThemeSelect } from '../../misc';

export interface DesktopNavBarProps {
	//
}

export const DesktopNavBar: FC<DesktopNavBarProps> = ({ children }) => {
	const { appPrimaryColor = Slate[100] } = useAppTheme();
	const { location, push } = useRouter();
	const { locale } = useLocale();

	const isCareerPage = location.pathname.includes('career');
	const isEngineeringPage = location.pathname.includes('engineering');
	const isProjectsPage = location.pathname.includes('projects');

	const handleClickLogo = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		push('/');
	};

	const handleClickCareer = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		push('/career');
	};

	const handleClickEngineering = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		push('/engineering');
	};

	const handleClickProjects = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		push('/projects');
	};

	const handleClickRequestAnInterview = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		push('/hire');
	};

	return (
		<>
			<Bar>
				<button
					className="col-span-1 w-full h-5/6 mx-auto my-0 mh-full inline-block"
					onClick={handleClickLogo}
				>
					<Logo className="h-5/6 m-0 w-full" color={appPrimaryColor} />
				</button>
				<div className="col-span-7 text-sm">
					<span
						className={`inline-flex border-l ${
							isCareerPage
								? 'bg-app-primary text-gray-100'
								: 'transition-colors dark:text-gray-100 hover:bg-gray-500 dark:hover:bg-gray-100 hover:text-gray-100 dark:hover:text-gray-500'
						}`}
					>
						<button
							className="w-full text-center p-4"
							onClick={handleClickCareer}
						>
							<ProgrammingIcon
								className="w-6 inline-block mr-2"
								primaryColor={isCareerPage ? 'black' : undefined}
							/>
							{(strings.careerBtn as Record<string, string>)[locale]}
						</button>
					</span>
					<span
						className={`inline-flex border-l ${
							isEngineeringPage
								? 'bg-app-primary text-gray-100'
								: 'transition-colors dark:text-gray-100 hover:bg-gray-500 dark:hover:bg-gray-100 hover:text-gray-100 dark:hover:text-gray-500'
						}`}
					>
						<button
							className="w-full text-center p-4"
							onClick={handleClickEngineering}
						>
							<ProgrammingOnScreenIcon
								className="w-6 inline-block mr-2"
								primaryColor={isEngineeringPage ? 'black' : undefined}
							/>
							{(strings.engineeringBtn as Record<string, string>)[locale]}
						</button>
					</span>
					<span
						className={`inline-flex border-l border-r ${
							isProjectsPage
								? 'bg-app-primary text-gray-100'
								: 'transition-colors dark:text-gray-100 hover:bg-gray-500 dark:hover:bg-gray-100 hover:text-gray-100 dark:hover:text-gray-500'
						}`}
					>
						<button
							className="w-full text-center p-4"
							onClick={handleClickProjects}
						>
							<ApplicationIcon
								className="w-6 inline-block mr-2"
								primaryColor={isProjectsPage ? 'black' : undefined}
							/>
							{(strings.projectsBtn as Record<string, string>)[locale]}
						</button>
					</span>
				</div>
				<div className="col-span-4 text-xs dark:text-gray-100">
					<div className="inline-flex p-4 justify-center">
						<button
							className="px-3 py-2 bg-app-primary text-white rounded-sm hover:shadow-md"
							onClick={handleClickRequestAnInterview}
						>
							{(strings.interviewRequestBtn as Record<string, string>)[locale]}
						</button>
					</div>
					<div className="inline-flex p-4 items-center">
						<LanguageSelect />
					</div>
					<div className="inline-flex p-4 items-center">
						<ThemeSelect />
					</div>
				</div>
			</Bar>
			<Content className="grid grid-cols-12 grid-rows-1 relative overflow-scroll w-full transition-colors duration-500 bg-gray-200 dark:bg-gray-500 text-app-gunmetal dark:text-gray-100">
				{children}
			</Content>
		</>
	);
};

const Content = styled.div`
	height: calc(100vh - 5.5175625rem);
`;
