import React, { FC, MutableRefObject, useRef, useMemo } from 'react';
import { Object3D, Color } from 'three';
import { useFrame } from 'react-three-fiber';

export interface DustProps {
	color?: Color;
	count?: number;
	mouse?: MutableRefObject<Array<number>>;
}

interface DustParticle {
	t: number;
	factor: number;
	speed: number;
	xFactor: number;
	yFactor: number;
	zFactor: number;
	mx: number;
	my: number;
}

export const Dust: FC<DustProps> = ({
	color = new Color('white'),
	count,
	mouse,
}) => {
	const mesh = useRef();
	const particleObject = useMemo(() => new Object3D(), []);

	// Generate some random positions, speed factors and timings
	const particles = useMemo<Array<DustParticle>>(() => {
		return Array.from<undefined, DustParticle>(
			{ length: count || 5000 },
			() => ({
				t: Math.random() * 100,
				factor: 20 + Math.random() * 100,
				speed: 0.0001 + Math.random() / 2000,
				xFactor: -50 + Math.random() * 100,
				yFactor: -50 + Math.random() * 100,
				zFactor: -50 + Math.random() * 100,
				mx: 0,
				my: 0,
			}),
		);
	}, [count]);

	// The innards of this hook will run every frame
	useFrame((_) => {
		// Run through the randomized data to calculate some movement
		particles.forEach((particle, i) => {
			let { t, factor, speed, xFactor, yFactor, zFactor } = particle;

			t = particle.t += speed / 2;

			const a = Math.cos(t) + Math.sin(t * 1) / 10;
			const b = Math.sin(t) + Math.cos(t * 2) / 10;
			const s = Math.cos(t);

			particle.mx += (mouse!.current[0] - particle.mx) * 0.01;
			particle.my += (mouse!.current[1] * -1 - particle.my) * 0.01;

			// Update the particleObject object
			particleObject.position.set(
				(particle.mx / 10) * a +
					xFactor +
					Math.cos((t / 5) * factor) +
					(Math.sin(t * 1) * factor) / 10,
				(particle.my / 10) * b +
					yFactor +
					Math.sin((t / 5) * factor) +
					(Math.cos(t * 2) * factor) / 10,
				(particle.my / 10) * b +
					zFactor +
					Math.cos((t / 5) * factor) +
					(Math.sin(t * 3) * factor) / 10,
			);

			particleObject.scale.set(s, s, s);
			particleObject.rotation.set(s * 5, s * 5, s * 5);
			particleObject.updateMatrix();

			// And apply the matrix to the instanced item
			// @ts-ignore
			mesh.current.setMatrixAt(i, particleObject.matrix);
		});
		// @ts-ignore
		mesh.current.instanceMatrix.needsUpdate = true;
	});

	return (
		<>
			// @ts-ignore
			<instancedMesh ref={mesh} args={[null, null, count]}>
				<dodecahedronBufferGeometry attach="geometry" args={[0.05, 0]} />
				<meshPhongMaterial attach="material" color={color} />
			</instancedMesh>
		</>
	);
};
