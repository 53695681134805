import { FooterSection, TitleSection } from '../../sections';
import { HigherOrderComponent as HOC } from '../../../../types';

export interface WithSections {
	Footer: typeof FooterSection;
	Title: typeof TitleSection;
}

export const withSections: HOC<WithSections> = (Component) => {
	(Component as Partial<WithSections>).Footer = FooterSection;
	(Component as Partial<WithSections>).Title = TitleSection;

	return Component as WithSections & typeof Component;
};
