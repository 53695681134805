import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface ServerIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const ServerIcon: FC<ServerIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 102.66 103.31"
			>
				<path
					d="M15,19.5a4.74,4.74,0,1,1,4.73-4.73A4.74,4.74,0,0,1,15,19.5ZM15,13a1.74,1.74,0,1,0,1.73,1.74A1.74,1.74,0,0,0,15,13Z"
					fill={primaryColor}
				/>
				<path
					d="M57.56,21.26a1.5,1.5,0,0,1-1.5-1.5V10.64a1.5,1.5,0,0,1,3,0v9.12A1.5,1.5,0,0,1,57.56,21.26Z"
					fill={primaryColor}
				/>
				<path
					d="M68.12,21.26a1.5,1.5,0,0,1-1.5-1.5V10.64a1.5,1.5,0,0,1,3,0v9.12A1.5,1.5,0,0,1,68.12,21.26Z"
					fill={primaryColor}
				/>
				<path
					d="M78.69,21.26a1.5,1.5,0,0,1-1.5-1.5V10.64a1.5,1.5,0,0,1,3,0v9.12A1.5,1.5,0,0,1,78.69,21.26Z"
					fill={primaryColor}
				/>
				<path
					d="M89.25,21.26a1.5,1.5,0,0,1-1.5-1.5V10.64a1.5,1.5,0,0,1,3,0v9.12A1.5,1.5,0,0,1,89.25,21.26Z"
					fill={primaryColor}
				/>
				<path
					d="M15,52.6a4.74,4.74,0,1,1,4.73-4.74A4.75,4.75,0,0,1,15,52.6Zm0-6.47a1.74,1.74,0,1,0,1.73,1.73A1.74,1.74,0,0,0,15,46.13Z"
					fill={primaryColor}
				/>
				<path
					d="M57.56,54.35a1.5,1.5,0,0,1-1.5-1.5V43.74a1.5,1.5,0,0,1,3,0v9.11A1.5,1.5,0,0,1,57.56,54.35Z"
					fill={primaryColor}
				/>
				<path
					d="M68.12,54.35a1.5,1.5,0,0,1-1.5-1.5V43.74a1.5,1.5,0,0,1,3,0v9.11A1.5,1.5,0,0,1,68.12,54.35Z"
					fill={primaryColor}
				/>
				<path
					d="M78.69,54.35a1.5,1.5,0,0,1-1.5-1.5V43.74a1.5,1.5,0,0,1,3,0v9.11A1.5,1.5,0,0,1,78.69,54.35Z"
					fill={primaryColor}
				/>
				<path
					d="M89.25,54.35a1.5,1.5,0,0,1-1.5-1.5V43.74a1.5,1.5,0,0,1,3,0v9.11A1.5,1.5,0,0,1,89.25,54.35Z"
					fill={primaryColor}
				/>
				<path
					d="M15,85.69A4.74,4.74,0,1,1,19.69,81,4.74,4.74,0,0,1,15,85.69Zm0-6.47A1.74,1.74,0,1,0,16.69,81,1.74,1.74,0,0,0,15,79.22Z"
					fill={primaryColor}
				/>
				<path
					d="M57.56,87.45a1.5,1.5,0,0,1-1.5-1.5V76.83a1.5,1.5,0,1,1,3,0V86A1.5,1.5,0,0,1,57.56,87.45Z"
					fill={primaryColor}
				/>
				<path
					d="M68.12,87.45a1.5,1.5,0,0,1-1.5-1.5V76.83a1.5,1.5,0,1,1,3,0V86A1.5,1.5,0,0,1,68.12,87.45Z"
					fill={primaryColor}
				/>
				<path
					d="M78.69,87.45a1.5,1.5,0,0,1-1.5-1.5V76.83a1.5,1.5,0,1,1,3,0V86A1.5,1.5,0,0,1,78.69,87.45Z"
					fill={primaryColor}
				/>
				<path
					d="M89.25,87.45a1.5,1.5,0,0,1-1.5-1.5V76.83a1.5,1.5,0,1,1,3,0V86A1.5,1.5,0,0,1,89.25,87.45Z"
					fill={primaryColor}
				/>
				<path
					d="M101.16,29.53H1.5A1.5,1.5,0,0,1,0,28V1.5A1.5,1.5,0,0,1,1.5,0h99.66a1.5,1.5,0,0,1,1.5,1.5V28A1.5,1.5,0,0,1,101.16,29.53ZM3,26.53H99.66V3H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M101.16,95.72H1.5A1.5,1.5,0,0,1,0,94.22V67.69a1.5,1.5,0,0,1,1.5-1.5h99.66a1.5,1.5,0,0,1,1.5,1.5V94.22A1.5,1.5,0,0,1,101.16,95.72ZM3,92.72H99.66V69.19H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M89.25,69.19H13.41a1.5,1.5,0,0,1-1.5-1.5V61.13a1.5,1.5,0,0,1,1.5-1.5H89.25a1.5,1.5,0,0,1,1.5,1.5v6.56A1.5,1.5,0,0,1,89.25,69.19Zm-74.34-3H87.75V62.63H14.91Z"
					fill={secondaryColor}
				/>
				<path
					d="M89.25,36.1H13.41a1.5,1.5,0,0,1-1.5-1.5V28a1.5,1.5,0,0,1,1.5-1.5H89.25a1.5,1.5,0,0,1,1.5,1.5V34.6A1.5,1.5,0,0,1,89.25,36.1Zm-74.34-3H87.75V29.53H14.91Z"
					fill={secondaryColor}
				/>
				<path
					d="M101.16,62.63H1.5A1.5,1.5,0,0,1,0,61.13V34.6a1.5,1.5,0,0,1,1.5-1.5h99.66a1.5,1.5,0,0,1,1.5,1.5V61.13A1.5,1.5,0,0,1,101.16,62.63ZM3,59.63H99.66V36.1H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M22.47,103.31H12a1.5,1.5,0,0,1-1.5-1.5V94.22a1.5,1.5,0,0,1,1.5-1.5H22.47a1.5,1.5,0,0,1,1.5,1.5v7.59A1.5,1.5,0,0,1,22.47,103.31Zm-9-3H21V95.72H13.5Z"
					fill={secondaryColor}
				/>
				<path
					d="M90.66,103.31H80.2a1.5,1.5,0,0,1-1.5-1.5V94.22a1.5,1.5,0,0,1,1.5-1.5H90.66a1.5,1.5,0,0,1,1.5,1.5v7.59A1.5,1.5,0,0,1,90.66,103.31Zm-9-3h7.46V95.72H81.7Z"
					fill={secondaryColor}
				/>
			</svg>
		);
	},
);
