/**
 * This typeguard is used in other object-related typeguards to keep them
 * readable. Otherwise, each property/method would have to be referenced as
 * `(value as Record<string, unknown>).property`.
 *
 * Note that lodash's `isObject()` uses the primitive `object` type, which is
 * overly difficult to use compared to `Record<string, unknown>`.
 */
export const isNonEmptyObject = (
	value: unknown,
): value is Record<string, unknown> => {
	return (
		typeof value === 'object' &&
		value !== null &&
		!(value instanceof Array) &&
		Object.keys(value).length > 0
	);
};
