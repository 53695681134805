import React, { FC } from 'react';
import styled from 'styled-components';
import { Slate } from '@com.robhameetman/design';
import { Bar } from '../Bar';
import { Logo } from '../../logo';
import { useAppTheme } from '../../../utils';

export interface MobileNavBarProps {
	//
}

export const MobileNavBar: FC<MobileNavBarProps> = ({ children }) => {
	const { appPrimaryColor = Slate[100] } = useAppTheme();

	return (
		<Grid>
			<Bar>
				<Link href="/">
					<CenteredLogo color={appPrimaryColor} />
				</Link>
			</Bar>
			<View>{children}</View>
		</Grid>
	);
};

const Grid = styled.div`
	display: grid;
	grid-template-rows: 3rem auto;
`;

const View = styled.div`
	display: grid;
	grid-row: 2 / 2;
`;

const Link = styled.a`
	display: block;
	width: 100%;
	height: 90%;
	margin: 0 auto;

	/**
	 * Had to hard-code this in order to fix an issue where the rendered <a> tag
	 * sometimes (resulting from race conditions) ends up with a computed width
	 * of 0, causing, the logo to display slightly right-of-center.
	 */
	min-width: 8.5611875rem;
`;

const CenteredLogo = styled(Logo)`
	height: 90%;
	width: auto;
`;
