import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLoadingContext } from '../../hooks';

export interface LoadingOverlayProps {}

export const LoadingOverlay: FC<LoadingOverlayProps> = ({ children }) => {
  const [hidden, setHidden] = useState(false);
  const { fin } = useLoadingContext();

  useEffect(() => {
    if (fin) {
      const timeout = setTimeout(() => setHidden(true), 500);

      return () => clearTimeout(timeout);
    }
  }, [fin]);

  return !fin ? <OverlayDiv hidden={hidden}>{children}</OverlayDiv> : null;
};

interface OverlayProps {
  hidden: boolean;
}

const OverlayDiv = styled.div<OverlayProps>`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #a5afc4;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transition: visibility 0.5s, opacity 0.5s;
`;
