import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface ProgrammingIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const ProgrammingIcon: FC<ProgrammingIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 101.97 103.19"
			>
				<path
					d="M8.71,10.74a1.5,1.5,0,0,1-1.06-.44,1.51,1.51,0,0,1,0-2.13A1.24,1.24,0,0,1,7.87,8a1.65,1.65,0,0,1,.26-.14,1,1,0,0,1,.28-.08,1.54,1.54,0,0,1,1.36.4,1.73,1.73,0,0,1,.32.49,1.43,1.43,0,0,1,0,1.15,1.73,1.73,0,0,1-.32.49A1.52,1.52,0,0,1,8.71,10.74Z"
					fill={secondaryColor}
				/>
				<path
					d="M19.48,9.23A1.5,1.5,0,0,1,21,7.74h0a1.5,1.5,0,0,1,1.5,1.49h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,19.48,9.23Z"
					fill={secondaryColor}
				/>
				<path
					d="M33.25,10.74a1.5,1.5,0,0,1-1.06-.44,1.37,1.37,0,0,1-.19-.23,2.15,2.15,0,0,1-.14-.26,1.47,1.47,0,0,1-.08-.28,1.5,1.5,0,0,1,0-.29,1.53,1.53,0,0,1,0-.3,1.47,1.47,0,0,1,.08-.28A2.15,2.15,0,0,1,32,8.4a1,1,0,0,1,.19-.22A1.3,1.3,0,0,1,32.41,8a1.65,1.65,0,0,1,.26-.14A1.52,1.52,0,0,1,33,7.76a1.47,1.47,0,0,1,.59,0,1.61,1.61,0,0,1,.54.23,1.37,1.37,0,0,1,.23.19,1,1,0,0,1,.18.22,1.3,1.3,0,0,1,.14.26,1.43,1.43,0,0,1,0,1.15,1.73,1.73,0,0,1-.32.49A1.52,1.52,0,0,1,33.25,10.74Z"
					fill={secondaryColor}
				/>
				<path
					d="M1.5,92.63A1.5,1.5,0,0,1,0,91.13V1.5a1.5,1.5,0,0,1,3,0V91.13A1.5,1.5,0,0,1,1.5,92.63Z"
					fill={secondaryColor}
				/>
				<path
					d="M74.82,34.35a1.5,1.5,0,0,1-1.5-1.5V15.15a1.5,1.5,0,0,1,3,0v17.7A1.5,1.5,0,0,1,74.82,34.35Z"
					fill={secondaryColor}
				/>
				<path
					d="M74.82,87.74a1.5,1.5,0,0,1-1.5-1.5v-16a1.5,1.5,0,1,1,3,0v16A1.51,1.51,0,0,1,74.82,87.74Z"
					fill={secondaryColor}
				/>
				<path
					d="M13.81,103.19q-.66,0-1.32-.06C5.37,102.54,0,97.35,0,91.05a1.5,1.5,0,0,1,3,0c0,4.72,4.19,8.63,9.74,9.09.35,0,.71,0,1.07,0,6,0,10.82-4.1,10.82-9.14V86.24a1.5,1.5,0,0,1,3,0v4.81C27.63,97.74,21.43,103.19,13.81,103.19Z"
					fill={secondaryColor}
				/>
				<path
					d="M72,103.19H13.81a1.5,1.5,0,0,1,0-3c6,0,10.82-4.1,10.82-9.14V86.24a1.5,1.5,0,0,1,1.5-1.5H84.27a1.5,1.5,0,0,1,1.5,1.5v4.81C85.78,97.74,79.58,103.19,72,103.19Zm-49.07-3H72c6,0,10.82-4.1,10.82-9.14V87.74H27.63v3.31A11.57,11.57,0,0,1,22.89,100.19Z"
					fill={secondaryColor}
				/>
				<path
					d="M74.82,16.65H1.5A1.5,1.5,0,0,1,0,15.15V1.5A1.5,1.5,0,0,1,1.5,0H74.82a1.5,1.5,0,0,1,1.5,1.5V15.15A1.5,1.5,0,0,1,74.82,16.65ZM3,13.65H73.32V3H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M74.84,78.68a1.53,1.53,0,0,1-1.06-.44,1.5,1.5,0,0,1-.44-1.06V71.65a22.15,22.15,0,0,1-2.89-.43,20.31,20.31,0,0,1-4.36-1.52l-2.78,4.81a1.49,1.49,0,0,1-2,.55,27.22,27.22,0,0,1-9.94-9.91,1.5,1.5,0,0,1,.54-2.05l4.8-2.79a20.07,20.07,0,0,1-2-7.26l-5.53,0h0a1.49,1.49,0,0,1-1.06-.43,1.54,1.54,0,0,1-.44-1.07A27.13,27.13,0,0,1,51.27,38a1.51,1.51,0,0,1,2-.56l4.81,2.77a20.54,20.54,0,0,1,5.29-5.31l-2.79-4.8a1.51,1.51,0,0,1,.54-2.05,26.84,26.84,0,0,1,9.38-3.33,27.73,27.73,0,0,1,4.18-.35h0a1.53,1.53,0,0,1,1.06.44,1.5,1.5,0,0,1,.44,1.06v5.53a20.15,20.15,0,0,1,7.25,1.93l2.77-4.81a1.51,1.51,0,0,1,2-.56,27.38,27.38,0,0,1,10,9.89,1.51,1.51,0,0,1-.54,2l-4.8,2.8a20.2,20.2,0,0,1,2,7.28l5.52-.05h0a1.49,1.49,0,0,1,1,.44,1.47,1.47,0,0,1,.45,1.06A27.19,27.19,0,0,1,98.33,65.1a1.49,1.49,0,0,1-2,.55l-4.81-2.78a20.43,20.43,0,0,1-5.3,5.31L89,73a1.5,1.5,0,0,1-.55,2,27.21,27.21,0,0,1-13.56,3.65Zm0-10a1.5,1.5,0,0,1,1.5,1.5v5.43a24.2,24.2,0,0,0,9.25-2.48l-2.74-4.72a1.51,1.51,0,0,1,.55-2.05,17.35,17.35,0,0,0,6.28-6.28,1.5,1.5,0,0,1,2-.55l4.71,2.73A24,24,0,0,0,98.92,53L93.5,53h0a1.51,1.51,0,0,1-1.06-.43A1.52,1.52,0,0,1,92,51.5a17.11,17.11,0,0,0-2.35-8.6,1.51,1.51,0,0,1-.16-1.14,1.56,1.56,0,0,1,.7-.91l4.71-2.75a24.21,24.21,0,0,0-6.79-6.74l-2.71,4.73a1.56,1.56,0,0,1-.91.7,1.51,1.51,0,0,1-1.14-.15,17.19,17.19,0,0,0-8.52-2.29,1.54,1.54,0,0,1-1.56-1.49l0-5.42c-.72,0-1.47.13-2.22.25a24.13,24.13,0,0,0-7,2.25l2.74,4.71a1.49,1.49,0,0,1-.54,2.05A17.24,17.24,0,0,0,59.94,43a1.5,1.5,0,0,1-2,.55l-4.72-2.71a24.07,24.07,0,0,0-2.46,9.24l5.43,0a1.44,1.44,0,0,1,1.06.44,1.5,1.5,0,0,1,.44,1.06A17.15,17.15,0,0,0,60,60.13a1.48,1.48,0,0,1-.54,2l-4.71,2.74a24.31,24.31,0,0,0,6.77,6.75L64.21,67a1.51,1.51,0,0,1,2.05-.55,17.17,17.17,0,0,0,4.85,1.89,17.73,17.73,0,0,0,3.67.42Z"
					fill={primaryColor}
				/>
				<path
					d="M74.86,61.32a9.22,9.22,0,1,1,9.22-9.21A9.23,9.23,0,0,1,74.86,61.32Zm0-15.43a6.22,6.22,0,1,0,6.22,6.22A6.23,6.23,0,0,0,74.86,45.89Z"
					fill={primaryColor}
				/>
			</svg>
		);
	},
);
