import React, { FC } from 'react';
import { Slate } from '@com.robhameetman/design';
import styled from 'styled-components';

export interface FooterSectionProps {
	//
}

export const FooterSection: FC<FooterSectionProps> = () => {
	return <Footer></Footer>;
};

const Footer = styled.section`
	background-color: ${Slate[500]};
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	flex-direction: row;
	padding: 5rem 0;
	min-width: 100%;

	@media (min-width: 481px) {
		font-size: 3rem;
	}

	@media (min-width: 769px) {
		font-size: 4rem;
	}

	@media (min-width: 1081px) {
		font-size: 6rem;
	}
`;
