/**
 * This typeguard is used in other object-related typeguards to keep them
 * readable. Otherwise, each property/method would have to be referenced as
 * `(value as Record<string, unknown>).property`.
 *
 * Note that lodash's `isObject()` uses the primitive `object` type, which is
 * considered an anti-pattern because it has no known keys. The type
 * `Record<string, unknown>` is better for representing generic object types
 * because it uses a `string` index signature to represent an object with
 * generic keys of `unknown` types, which keeps the TS compiler happy when
 * validating such object types.
 */
export const isObject = (value: unknown): value is Record<string, unknown> => {
	return (
		typeof value === 'object' && value !== null && !(value instanceof Array)
	);
};
