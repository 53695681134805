import { Dispatch, SetStateAction, createContext } from 'react';

export interface GlobalAppContext {
	readonly currentBreakpoint: string;
	readonly locale: string;
	readonly theme: 0 | 1 | null;
	readonly setDarkTheme: () => void;
	readonly setLightTheme: () => void;
	readonly setLocale: Dispatch<SetStateAction<string>>;
}

export const AppContext = createContext<GlobalAppContext>({
	currentBreakpoint: 'sm',
	locale: navigator.languages[0],
	theme: null,
	setDarkTheme: () => {},
	setLightTheme: () => {},
	setLocale: () => {},
});
