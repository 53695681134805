import React, { FC } from 'react';
import {
	ElixirIcon,
	GraphQLIcon,
	PlanningIcon,
	ReactIcon,
	StopwatchIcon,
	TailwindIcon,
	TypeScriptIcon,
} from '../../../lib';

export interface I11InfoProps {
	/* TODO */
}

export const I11Info: FC<I11InfoProps> = () => {
	return (
		<>
			<div className="col-span-1" />
			<div className="col-span-4 mr-4">
				<h3 className="pb-3">
					<strong>Responsibilities</strong>
				</h3>
				<ul className="hanging-list-item">
					<li className="mb-6">
						{'>'}&nbsp;&nbsp; Hire, build, and coach an industry-leading Front
						End practice to deliver well-tested and well-architected solutions
						across packages and projects with cutting-edge technologies.
					</li>
					<li className="my-6">
						{'>'}&nbsp;&nbsp; Lead a team of Front End Engineers, managing daily
						tasks and conducting regular continuous performance reviews.
					</li>
					<li className="my-6">
						{'>'}&nbsp;&nbsp; Work closely with Product and Executive Leadership
						to develop roadmaps that meet the goals of the organization, with
						consideration of KPI-driven initiatives and compliance/security
						requirements.
					</li>
					<li className="mt-6">
						{'>'}&nbsp;&nbsp; Establish and maintain Front End Engineering core
						principles and processes to ensure the delivery of timely excellence
						in support of the company vision and goals.
					</li>
				</ul>
			</div>
			<div className="col-span-4 mx-4 text-app-primary">
				<h3 className="pb-3">
					<strong>Achievements</strong>
				</h3>
				<ul>
					<li className="flex items-start mb-6 border border-app-primary rounded p-3">
						<span className="inline-flex items-center justify-center w-7 px-1 py-1.5 rounded mt-1 mr-3 bg-app-primary text-gray-100">
							<PlanningIcon className="w-4" primaryColor="white" />
						</span>
						Grew my team from 2 to 6 Engineers
					</li>
					<li className="flex items-start mt-6 border border-app-primary rounded p-3">
						<span className="inline-flex items-center justify-center w-16 pt-1 px-1 pb-1.5 rounded mt-1 mr-3 bg-app-primary text-gray-100">
							<StopwatchIcon className="w-4" primaryColor="white" />
						</span>
						Increased platform-wide Time To Interaction performance during
						periods of peak activity by up to 600%.
					</li>
				</ul>
			</div>
			<div className="col-span-2 ml-4">
				<h3 className="pb-3">
					<strong>Tech Stack</strong>
				</h3>
				<div className="grid grid-cols-3 gap-2">
					<TypeScriptIcon />
					<ReactIcon />
					<TailwindIcon />
					<GraphQLIcon />
					<ElixirIcon />
				</div>
			</div>
			<div className="col-span-1" />
		</>
	);
};
