import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface PlanningIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const PlanningIcon: FC<PlanningIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 103.82 103.69"
			>
				<path
					d="M63.65,19.54H40.16a6.39,6.39,0,0,1-6.38-6.39V6.39A6.39,6.39,0,0,1,40.16,0H63.65A6.39,6.39,0,0,1,70,6.39v6.76A6.4,6.4,0,0,1,63.65,19.54ZM40.16,3a3.39,3.39,0,0,0-3.38,3.39v6.76a3.39,3.39,0,0,0,3.38,3.39H63.65A3.39,3.39,0,0,0,67,13.15V6.39A3.39,3.39,0,0,0,63.65,3Z"
					fill={secondaryColor}
				/>
				<path
					d="M62.49,103.69H41.57A7.68,7.68,0,0,1,33.9,96V77a7.68,7.68,0,0,1,7.67-7.67H62.49A7.68,7.68,0,0,1,70.16,77V96A7.68,7.68,0,0,1,62.49,103.69ZM41.57,72.29A4.67,4.67,0,0,0,36.9,77V96a4.67,4.67,0,0,0,4.67,4.67H62.49A4.68,4.68,0,0,0,67.16,96V77a4.68,4.68,0,0,0-4.67-4.67Z"
					fill={primaryColor}
				/>
				<path
					d="M70.43,48h-37a3.65,3.65,0,0,1-3.65-3.64V38.63A3.65,3.65,0,0,1,33.39,35h37a3.65,3.65,0,0,1,3.65,3.64v5.68A3.65,3.65,0,0,1,70.43,48Zm-37-10a.65.65,0,0,0-.65.64v5.68a.65.65,0,0,0,.65.64h37a.65.65,0,0,0,.65-.64V38.63a.65.65,0,0,0-.65-.64Z"
					fill={secondaryColor}
				/>
				<path
					d="M51.91,37.12a1.5,1.5,0,0,1-1.5-1.5V18.91a1.5,1.5,0,1,1,3,0V35.62A1.5,1.5,0,0,1,51.91,37.12Z"
					fill={secondaryColor}
				/>
				<path
					d="M89.21,72.29a1.5,1.5,0,0,1-1.5-1.5V64.9A3,3,0,0,0,84.76,62H19.06a3,3,0,0,0-2.95,2.94v5.89a1.5,1.5,0,0,1-3,0V64.9A6,6,0,0,1,19.06,59h65.7a6,6,0,0,1,5.95,5.94v5.89A1.5,1.5,0,0,1,89.21,72.29Z"
					fill={secondaryColor}
				/>
				<path
					d="M51.91,62a1.5,1.5,0,0,1-1.5-1.5v-14a1.5,1.5,0,0,1,3,0v14A1.5,1.5,0,0,1,51.91,62Z"
					fill={secondaryColor}
				/>
				<path
					d="M97.45,97.51H81a6.37,6.37,0,0,1-6.37-6.36v-15A6.38,6.38,0,0,1,81,69.76H97.45a6.38,6.38,0,0,1,6.37,6.37v15A6.37,6.37,0,0,1,97.45,97.51ZM81,72.76a3.37,3.37,0,0,0-3.37,3.37v15A3.37,3.37,0,0,0,81,94.51H97.45a3.37,3.37,0,0,0,3.37-3.36v-15a3.37,3.37,0,0,0-3.37-3.37Z"
					fill={primaryColor}
				/>
				<path
					d="M22.85,97.51H6.36A6.36,6.36,0,0,1,0,91.15v-15a6.37,6.37,0,0,1,6.36-6.37H22.85a6.37,6.37,0,0,1,6.36,6.37v15A6.36,6.36,0,0,1,22.85,97.51ZM6.36,72.76A3.37,3.37,0,0,0,3,76.13v15a3.37,3.37,0,0,0,3.36,3.36H22.85a3.36,3.36,0,0,0,3.36-3.36v-15a3.36,3.36,0,0,0-3.36-3.37Z"
					fill={primaryColor}
				/>
			</svg>
		);
	},
);
