import React, { FC } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { EngineeringView } from '../engineering';
import { ProjectDetailsView, ProjectListView } from '../projects';
import { CareerView, InterviewRequestView } from '../career';
import { HomeView } from '../utils';

export const Routes: FC = () => {
	return (
		<Router>
			<Switch>
				<Route path="/" exact component={HomeView} />

				{/* Careers */}
				<Route path="/career" component={CareerView} />
				<Route path="/hire" component={InterviewRequestView} />

				{/* Engineering */}
				<Route
					path="/engineering/:domain/:subdomain"
					component={EngineeringView}
				/>
				<Route path="/engineering/:domain" component={EngineeringView} />
				<Route path="/engineering" component={EngineeringView} />

				{/* Projects */}
				<Route path="/projects" exact component={ProjectListView} />
				<Route path="/projects/:projectId" component={ProjectDetailsView} />
			</Switch>
		</Router>
	);
};
