import React, { FC } from 'react';
import { View } from '../../../lib';

export interface InterviewRequestViewProps {
	/* TODO */
}

export const InterviewRequestView: FC<InterviewRequestViewProps> = () => {
	return (
		<View>
			<div className="col-span-2" />
			<div className="col-span-8">
				<section className="mt-16">
					<h1 className="text-3xl text-app-primary">Hire Me</h1>
				</section>
			</div>
			<div className="col-span-2" />
		</View>
	);
};
