import { useEffect, useState } from 'react';

export interface Viewport {
	height: number;
	width: number;
}

export const useViewport = (): Viewport => {
	const [height, setHeight] = useState(window.innerHeight);
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		const listener = () => {
			setHeight(window.innerHeight);
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', listener);
		return () => window.removeEventListener('resize', listener);
	}, []);

	return { height, width };
};
