import { useMemo } from 'react';
import {
	match as Match,
	useHistory,
	useLocation,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import { useQueryParams } from '../useQueryParams';
import { History, Location, RouteAgent } from '../../types';

export interface UseRouterResult<P = Record<string, unknown>> {
	readonly pathname: Location['pathname'];
	readonly queryParams: Record<string, string>;
	readonly search: Location['search'];
	readonly params: P;
	readonly match: Match<P>;
	readonly location: Location;
	readonly history: History;
	readonly push: History['push'];
	readonly replace: History['replace'];
}

export const useRouter = <P = Record<string, unknown>>(): UseRouterResult<
	P
> => {
	const params = useParams<P>();
	const queryParams = useQueryParams();
	const location = useLocation<RouteAgent>();
	const history = useHistory<RouteAgent>();
	const match = useRouteMatch<P>();

	const { push, replace } = history;
	const { pathname, search } = location;

	return useMemo(() => {
		return {
			history,
			location,
			match,
			params: (params as unknown) as P,
			pathname,
			push,
			queryParams,
			replace,
			search,
		};
	}, [
		pathname,
		queryParams,
		search,
		params,
		match,
		location,
		history,
		push,
		replace,
	]);
};
