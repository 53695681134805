/**
 * Retrieve the table cache from LocalStorage if available.
 *
 * @param key - The key of the item in LocalStorage.
 *
 * @returns The item cached in LocalStorage with the given key or `null`.
 */
export const getCached = <T>(key: string): T | null => {
	const cached = window.localStorage.getItem(key);

	return cached ? JSON.parse(cached) : null;
};
