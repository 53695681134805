import React, { FC } from 'react';
import { ExperienceListItem } from '../ExperienceListItem';
import { GdnInfo, I11Info, L2Info, StxInfo, W2cInfo } from '../../experience';

export interface ExperienceListProps {
	/* TODO */
}

export const ExperienceList: FC<ExperienceListProps> = () => {
	return (
		<>
			<ExperienceListItem
				id="stx"
				company="SportsX"
				position="Principal Solutions Engineer"
			>
				<StxInfo />
			</ExperienceListItem>
			<ExperienceListItem
				id="i11"
				company="Inspire11"
				position="Consultant, Application Architecture"
			>
				<I11Info />
			</ExperienceListItem>
			<ExperienceListItem
				id="gdn"
				company="The Graide Network"
				position="Sr. Full Stack Engineer"
			>
				<GdnInfo />
			</ExperienceListItem>
			<ExperienceListItem
				id="l2"
				company="L2 Interactive"
				position="Full Stack Engineer"
			>
				<L2Info />
			</ExperienceListItem>
			<ExperienceListItem
				id="w2c"
				company="Web2Carz"
				position="Front End Engineer"
			>
				<W2cInfo />
			</ExperienceListItem>
		</>
	);
};
