import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface OffIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const OffIcon: FC<OffIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 82.31 82.53"
			>
				<path
					d="M55,57.32,80.19,82.53l2.12-2.12-27.2-27.2A17.87,17.87,0,0,0,55,55.29Z"
					fill="none"
				/>
				<path
					d="M47.19,45.29l-.59,3.64L52,54.36a20.27,20.27,0,0,1,.5-3.75Z"
					fill="none"
				/>
				<path
					d="M26,24l-8.62-8.62A23.17,23.17,0,0,0,16,18.32l10.8,10.79Z"
					fill="none"
				/>
				<polygon
					points="30.41 32.75 43.98 46.32 44.58 42.67 29.58 27.68 30.41 32.75"
					fill="none"
				/>
				<polygon
					points="29.58 27.68 25.95 24.04 26.78 29.11 30.41 32.75 29.58 27.68"
					fill={secondaryColor}
				/>
				<polygon
					points="43.39 49.97 41.4 62.1 44.44 62.1 46 52.58 43.39 49.97"
					fill={primaryColor}
				/>
				<polygon
					points="27.61 34.19 32.18 62.1 35.22 62.1 31.25 37.82 27.61 34.19"
					fill={primaryColor}
				/>
				<path
					d="M51.31,20a1.11,1.11,0,0,0,0-.17.5.5,0,0,0,0-.12c0-.06,0-.1,0-.15s0-.09,0-.13a.54.54,0,0,0-.07-.13.75.75,0,0,0-.07-.12L51,19.06l-.09-.1-.12-.1-.11-.08-.13-.07-.13-.07-.13,0-.16,0h-.39A3.91,3.91,0,0,0,46.8,20c-.46.49-.55.55-.83.55s-.37-.06-.82-.55a3.79,3.79,0,0,0-6,0c-.46.49-.55.55-.83.55s-.36-.06-.82-.55a3.79,3.79,0,0,0-6,0c-.45.49-.54.55-.82.55s-.37-.06-.82-.55a3.92,3.92,0,0,0-2.91-1.48h-.37l-.16,0-.13,0-.14.07-.12.07a.48.48,0,0,0-.11.09.48.48,0,0,0-.11.09.52.52,0,0,0-.1.11l-.09.1-.08.14s0,0,0,0h0l3.26,3.26.81.82a4,4,0,0,0,1.08.15,3.89,3.89,0,0,0,3-1.49c.45-.49.55-.55.82-.55s.37.06.82.55a3.79,3.79,0,0,0,6,0c.46-.49.55-.55.83-.55s.37.06.82.55a3.91,3.91,0,0,0,3,1.49,3.56,3.56,0,0,0,1.82-.48L45.18,39l2.61,2.61,3.5-21.37a.49.49,0,0,0,0-.12s0-.08,0-.12Z"
					fill={primaryColor}
				/>
				<path
					d="M52,54.36l3,3v-2a17.87,17.87,0,0,1,.13-2.08l-2.59-2.6A20.27,20.27,0,0,0,52,54.36Z"
					fill={secondaryColor}
				/>
				<path
					d="M36.72,3.05A23.94,23.94,0,0,1,56.55,42.39a18.81,18.81,0,0,0-3,5l2.33,2.33a16,16,0,0,1,3-5.39,26.95,26.95,0,0,0-2.14-37A26.7,26.7,0,0,0,36.53.06,27,27,0,0,0,16.86,10.71L19,12.86A24,24,0,0,1,36.72,3.05Z"
					fill={secondaryColor}
				/>
				<polygon
					points="82.31 80.41 47.19 45.29 44.58 42.67 2.12 0.22 0 2.34 0 2.34 0 2.34 13.68 16.02 15.4 17.73 43.98 46.32 43.98 46.32 46.6 48.93 46.6 48.93 80.19 82.53 82.31 80.41"
					fill={secondaryColor}
				/>
				<path
					d="M52,58.56V62.1H24.54V55a20.31,20.31,0,0,0-4.76-12.85,23.93,23.93,0,0,1-5.44-16.35A23.34,23.34,0,0,1,15,21.53l-2.44-2.44a26.32,26.32,0,0,0-1.16,6.59,26.93,26.93,0,0,0,6.12,18.4A17.35,17.35,0,0,1,21.54,55V65.69a16.72,16.72,0,0,0,33.44,0V61.56Zm0,7.13a13.72,13.72,0,0,1-27.44,0V65.1H52Z"
					fill={secondaryColor}
				/>
			</svg>
		);
	},
);
