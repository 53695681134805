import React, { FC } from 'react';
import styled from 'styled-components';

export interface LinkedinIconProps {}

export const LinkedinIcon: FC<LinkedinIconProps> = () => {
  return <Icon className="fab fa-linkedin" />;
};

const Icon = styled.i``;
