import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface QualityIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const QualityIcon: FC<QualityIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 103.68 104.38"
			>
				<path
					d="M102.18,18.43H1.5A1.5,1.5,0,0,1,0,16.93V1.5A1.5,1.5,0,0,1,1.5,0H102.18a1.5,1.5,0,0,1,1.5,1.5V16.93A1.5,1.5,0,0,1,102.18,18.43ZM3,15.43h97.68V3H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M102.18,104.38H1.5a1.5,1.5,0,0,1-1.5-1.5V16.93a1.5,1.5,0,0,1,1.5-1.5H102.18a1.5,1.5,0,0,1,1.5,1.5v85.95A1.5,1.5,0,0,1,102.18,104.38ZM3,101.38h97.68V18.43H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M8.51,11.26a1.56,1.56,0,0,1-.57-.11,1.63,1.63,0,0,1-.49-.33,1.58,1.58,0,0,1-.33-.48A1.62,1.62,0,0,1,7,9.76a1.43,1.43,0,0,1,0-.29,1.31,1.31,0,0,1,.09-.28,1.26,1.26,0,0,1,.13-.26,2.11,2.11,0,0,1,.19-.23,1.73,1.73,0,0,1,.49-.32,1.31,1.31,0,0,1,.28-.09,1.54,1.54,0,0,1,1.35.41,2.11,2.11,0,0,1,.19.23,1.26,1.26,0,0,1,.13.26,1.31,1.31,0,0,1,.09.28,1.43,1.43,0,0,1,0,.29,1.62,1.62,0,0,1-.11.58,1.58,1.58,0,0,1-.33.48A1.46,1.46,0,0,1,8.51,11.26Z"
					fill={secondaryColor}
				/>
				<path
					d="M19.1,9.76a1.49,1.49,0,0,1,1.49-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.49,1.49,0,0,1,19.1,9.76Z"
					fill={secondaryColor}
				/>
				<path
					d="M32.68,11.26a1.46,1.46,0,0,1-1.06-.44,1.58,1.58,0,0,1-.33-.48,1.62,1.62,0,0,1-.11-.58,1.43,1.43,0,0,1,0-.29,2.29,2.29,0,0,1,.08-.28,2.15,2.15,0,0,1,.14-.26,2.11,2.11,0,0,1,.19-.23A1.52,1.52,0,0,1,33,8.29a1.41,1.41,0,0,1,1,.64.94.94,0,0,1,.14.26,1.31,1.31,0,0,1,.09.28,1.45,1.45,0,0,1-.09.87,1.39,1.39,0,0,1-.32.48A1.47,1.47,0,0,1,32.68,11.26Z"
					fill={secondaryColor}
				/>
				<path
					d="M52.51,70.78h-.07a1.5,1.5,0,0,1-1.3-.89l-4-9.1a1.5,1.5,0,1,1,2.74-1.21L52.68,66l5.46-9.59a1.5,1.5,0,1,1,2.61,1.49L53.82,70A1.51,1.51,0,0,1,52.51,70.78Z"
					fill={secondaryColor}
				/>
				<path
					d="M73.1,93.42a1.57,1.57,0,0,1-.7-.17L52.3,82.68,32.19,93.25a1.51,1.51,0,0,1-1.58-.12A1.48,1.48,0,0,1,30,91.67l3.84-22.39L17.59,53.43a1.52,1.52,0,0,1-.38-1.54,1.49,1.49,0,0,1,1.21-1L40.9,47.6,51,27.23a1.51,1.51,0,0,1,1.35-.83,1.49,1.49,0,0,1,1.34.83L63.7,47.6l22.47,3.27a1.47,1.47,0,0,1,1.21,1A1.5,1.5,0,0,1,87,53.43L70.74,69.28l3.84,22.39a1.51,1.51,0,0,1-1.48,1.75Zm-51.24-40L36.52,67.68A1.52,1.52,0,0,1,37,69L33.49,89.18,51.6,79.66a1.51,1.51,0,0,1,1.4,0l18.11,9.52L67.65,69a1.52,1.52,0,0,1,.43-1.33L82.73,53.4,62.48,50.46a1.55,1.55,0,0,1-1.13-.82L52.3,31.29,43.24,49.64a1.53,1.53,0,0,1-1.13.82Z"
					fill={primaryColor}
				/>
			</svg>
		);
	},
);
