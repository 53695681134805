import React, { FC, useEffect, useState } from 'react';
import { useLoadingContext } from '../../hooks';
import styled from 'styled-components';

export interface LoadingContentProps {}

export const LoadingContent: FC<LoadingContentProps> = ({ children }) => {
  const [hidden, setHidden] = useState(true);
  const { fin } = useLoadingContext();

  useEffect(() => {
    setHidden(fin);
  }, [fin]);

  useEffect(() => {
    setTimeout(() => setHidden(false), 500);
  }, []);
  return <Content hidden={hidden}>{children}</Content>;
};

interface ContentProps {
  hidden: boolean;
}

const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  width: 10rem;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  transition: visibility 0.5s, opacity 0.5s linear;
`;
