import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface TestingIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const TestingIcon: FC<TestingIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 103.16 103.56"
			>
				<path
					d="M101.66,18.31H1.5A1.5,1.5,0,0,1,0,16.81V1.5A1.5,1.5,0,0,1,1.5,0H101.66a1.5,1.5,0,0,1,1.5,1.5V16.81A1.5,1.5,0,0,1,101.66,18.31ZM3,15.31h97.16V3H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M101.66,103.56H1.5a1.5,1.5,0,0,1-1.5-1.5V16.81a1.5,1.5,0,0,1,1.5-1.5H101.66a1.5,1.5,0,0,1,1.5,1.5v85.25A1.5,1.5,0,0,1,101.66,103.56ZM3,100.56h97.16V18.31H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M8.56,11.42A1.52,1.52,0,0,1,7.5,11a1.49,1.49,0,0,1-.43-1.06A1.55,1.55,0,0,1,7.5,8.86a1.47,1.47,0,0,1,1.64-.32,1.26,1.26,0,0,1,.26.13,2.11,2.11,0,0,1,.23.19,1.52,1.52,0,0,1,.44,1.06,1.39,1.39,0,0,1-.12.57,1.34,1.34,0,0,1-.32.49A1.54,1.54,0,0,1,8.56,11.42Z"
					fill={secondaryColor}
				/>
				<path
					d="M19.25,9.92a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,19.25,9.92Z"
					fill={secondaryColor}
				/>
				<path
					d="M32.93,11.42A1.5,1.5,0,0,1,31.87,11a1.46,1.46,0,0,1-.44-1.06,1.52,1.52,0,0,1,.44-1.06,1.5,1.5,0,0,1,1.35-.41,1.31,1.31,0,0,1,.28.09,1.26,1.26,0,0,1,.26.13,2.11,2.11,0,0,1,.23.19,1.52,1.52,0,0,1,.44,1.06A1.5,1.5,0,0,1,34,11a1.63,1.63,0,0,1-.49.33A1.56,1.56,0,0,1,32.93,11.42Z"
					fill={secondaryColor}
				/>
				<path
					d="M76,50.88A1.49,1.49,0,0,1,74.64,50l-4.52-9.73A1.5,1.5,0,1,1,72.84,39l3.1,6.67,4.84-11.33a1.5,1.5,0,0,1,2.76,1.18L77.38,50a1.52,1.52,0,0,1-1.36.91Z"
					fill={primaryColor}
				/>
				<path
					d="M88.81,55.61H63.31a1.5,1.5,0,0,1-1.5-1.5V28.61a1.5,1.5,0,0,1,1.5-1.5h25.5a1.5,1.5,0,0,1,1.5,1.5v25.5A1.5,1.5,0,0,1,88.81,55.61Zm-24-3h22.5V30.11H64.81Z"
					fill={secondaryColor}
				/>
				<path
					d="M76,87.62a1.47,1.47,0,0,1-1.36-.87L70.12,77a1.5,1.5,0,1,1,2.72-1.26l3.1,6.67L80.78,71.1a1.5,1.5,0,1,1,2.76,1.18L77.38,86.71a1.51,1.51,0,0,1-1.36.91Z"
					fill={primaryColor}
				/>
				<path
					d="M88.81,92.35H63.31a1.5,1.5,0,0,1-1.5-1.5V65.35a1.5,1.5,0,0,1,1.5-1.5h25.5a1.5,1.5,0,0,1,1.5,1.5v25.5A1.5,1.5,0,0,1,88.81,92.35Zm-24-3h22.5V66.85H64.81Z"
					fill={secondaryColor}
				/>
				<path
					d="M53.42,37.45H13.92a1.5,1.5,0,0,1,0-3h39.5a1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M53.42,47H13.92a1.5,1.5,0,0,1,0-3h39.5a1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M53.42,73.84H13.92a1.5,1.5,0,0,1,0-3h39.5a1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M53.42,83.37H13.92a1.5,1.5,0,0,1,0-3h39.5a1.5,1.5,0,1,1,0,3Z"
					fill={primaryColor}
				/>
			</svg>
		);
	},
);
