import React, { FC, useCallback, useLayoutEffect, useState } from 'react';
import { useRouter } from '../../../routes';

export interface SubdomainListItemProps {
	readonly selected?: boolean;
	readonly subdomain: string;
}

export const SubdomainListItem: FC<SubdomainListItemProps> = ({
	children,
	selected: _selected = false,
	subdomain: _subdomain,
}) => {
	const { params, replace } = useRouter();
	const { domain, subdomain } = params;

	if (!subdomain) {
		replace(`/engineering/${domain}/${_subdomain}`);
	}

	const [selected, setSelected] = useState(
		_selected || subdomain === _subdomain,
	);

	const handleClick = useCallback(() => {
		if (!selected) {
			console.log(
				`replacing router state to: '/engineering/${domain}/${_subdomain}'`,
			);
			replace(`/engineering/${domain}/${_subdomain}`);
		}
	}, [domain, _subdomain, selected, replace]);

	useLayoutEffect(() => {
		setSelected(_selected || subdomain === _subdomain);
	}, [params, _selected, subdomain, _subdomain]);

	return (
		<li
			className={`p-3 transition-colors duration-500 border-b ${
				!selected ? 'cursor-pointer' : ''
			} border-gray-100 dark:border-app-gunmetal ${
				selected
					? 'bg-gray-100 dark:bg-app-gunmetal'
					: 'hover:bg-gray-100 dark:hover:bg-app-gunmetal'
			}`}
			onClick={handleClick}
		>
			{children}
		</li>
	);
};
