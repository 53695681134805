import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface ApplicationIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const ApplicationIcon: FC<ApplicationIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 102.36 101.64"
			>
				<path
					d="M100.86,18H1.5A1.5,1.5,0,0,1,0,16.52V1.5A1.5,1.5,0,0,1,1.5,0h99.36a1.5,1.5,0,0,1,1.5,1.5v15A1.5,1.5,0,0,1,100.86,18ZM3,15H99.36V3H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M100.86,101.64H1.5a1.5,1.5,0,0,1-1.5-1.5V16.52A1.5,1.5,0,0,1,1.5,15h99.36a1.5,1.5,0,0,1,1.5,1.5v83.62A1.5,1.5,0,0,1,100.86,101.64ZM3,98.64H99.36V18H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M8.51,11.27l-.29,0a1.52,1.52,0,0,1-.28-.09A.94.94,0,0,1,7.68,11a1.3,1.3,0,0,1-.23-.18,1.47,1.47,0,0,1-.32-1.64,1.65,1.65,0,0,1,.14-.26,1.24,1.24,0,0,1,.18-.22,1.55,1.55,0,0,1,2.12,0,1.3,1.3,0,0,1,.19.22c.05.09.1.17.14.26a1,1,0,0,1,.08.28,1.5,1.5,0,0,1-.41,1.36A1.5,1.5,0,0,1,8.51,11.27Z"
					fill={secondaryColor}
				/>
				<path
					d="M19.1,9.77a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,19.1,9.77Z"
					fill={secondaryColor}
				/>
				<path
					d="M32.68,11.27a1.36,1.36,0,0,1-.29,0,1.31,1.31,0,0,1-.28-.09.94.94,0,0,1-.26-.14,1,1,0,0,1-.23-.18,1.47,1.47,0,0,1-.32-1.64,1.58,1.58,0,0,1,.13-.26,2,2,0,0,1,.19-.22,1.37,1.37,0,0,1,.23-.19l.26-.14.28-.08a1.5,1.5,0,0,1,1.35.41,2,2,0,0,1,.19.22c0,.09.1.17.14.26s.06.19.08.28a1.5,1.5,0,0,1-1.47,1.8Z"
					fill={secondaryColor}
				/>
				<path
					d="M27.37,53.11h-8a6.72,6.72,0,0,1-6.71-6.71v-8a6.72,6.72,0,0,1,6.71-6.71h8a6.72,6.72,0,0,1,6.71,6.71v8A6.72,6.72,0,0,1,27.37,53.11Zm-8-18.42a3.72,3.72,0,0,0-3.71,3.71v8a3.72,3.72,0,0,0,3.71,3.71h8a3.72,3.72,0,0,0,3.71-3.71v-8a3.72,3.72,0,0,0-3.71-3.71Z"
					fill={primaryColor}
				/>
				<path
					d="M60.55,53.11H42.14a1.5,1.5,0,0,1-1.5-1.5V33.19a1.5,1.5,0,0,1,1.5-1.5H60.55a1.5,1.5,0,0,1,1.5,1.5V51.61A1.5,1.5,0,0,1,60.55,53.11Zm-16.91-3H59.05V34.69H43.64Z"
					fill={secondaryColor}
				/>
				<path
					d="M83.32,53.11h-8a6.72,6.72,0,0,1-6.71-6.71v-8a6.72,6.72,0,0,1,6.71-6.71h8A6.72,6.72,0,0,1,90,38.4v8A6.72,6.72,0,0,1,83.32,53.11Zm-8-18.42a3.72,3.72,0,0,0-3.71,3.71v8a3.72,3.72,0,0,0,3.71,3.71h8A3.72,3.72,0,0,0,87,46.4v-8a3.72,3.72,0,0,0-3.71-3.71Z"
					fill={primaryColor}
				/>
				<path
					d="M32.46,84.61H14.05a1.5,1.5,0,0,1-1.5-1.5V64.69a1.5,1.5,0,0,1,1.5-1.5H32.46a1.5,1.5,0,0,1,1.5,1.5V83.11A1.5,1.5,0,0,1,32.46,84.61Zm-16.91-3H31V66.19H15.55Z"
					fill={secondaryColor}
				/>
				<path
					d="M55.23,84.61h-8a6.72,6.72,0,0,1-6.71-6.71v-8a6.72,6.72,0,0,1,6.71-6.71h8a6.72,6.72,0,0,1,6.71,6.71v8A6.72,6.72,0,0,1,55.23,84.61Zm-8-18.42a3.72,3.72,0,0,0-3.71,3.71v8a3.72,3.72,0,0,0,3.71,3.71h8a3.72,3.72,0,0,0,3.71-3.71v-8a3.72,3.72,0,0,0-3.71-3.71Z"
					fill={primaryColor}
				/>
				<path
					d="M88.41,84.61H70a1.5,1.5,0,0,1-1.5-1.5V64.69a1.5,1.5,0,0,1,1.5-1.5H88.41a1.5,1.5,0,0,1,1.5,1.5V83.11A1.5,1.5,0,0,1,88.41,84.61Zm-16.91-3H86.91V66.19H71.5Z"
					fill={secondaryColor}
				/>
			</svg>
		);
	},
);
