import React, { FC, useCallback, useEffect, useState } from 'react';
import { LoadingContext } from './LoadingContext';

export interface LoadingProviderProps {}

export const LoadingProvider: FC<LoadingProviderProps> = ({ children }) => {
  const [fin, setFin] = useState(false);
  const [progress, setProgress] = useState(0);

  const end = useCallback(() => setFin(true), []);
  const addToProgress = useCallback((value = 0) => setProgress(progress + value), [progress]);

  useEffect(() => {
    //
  }, [progress]);
  return (
    <LoadingContext.Provider value={{ fin, progress, addToProgress, end }}>
      {children}
    </LoadingContext.Provider>
  );
};
