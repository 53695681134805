/**
 * This typeguard is used in other object-related
 * typeguards to help keep them readable.
 */
export const getLocale = (): string =>
	Array.from(
		new Set(
			navigator.languages.map<string>((locale) =>
				locale.trim().split(/-|_/)[0].toLowerCase(),
			),
		),
	)[0];
