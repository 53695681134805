import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLoadingContext } from '../../hooks';
import { useTransition, animated } from 'react-spring';

interface Message {
  message: string;
  key: string;
}

export interface LoadingMessagesProps {
  messages: Array<Message>;
}

export const LoadingMessages: FC<LoadingMessagesProps> = ({ messages }) => {
  const { progress, addToProgress, end } = useLoadingContext();
  const [index, setIndex] = useState(0);

  const transitions = useTransition(messages[index], (item) => item.key, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { tension: 220, friction: 30 },
  });

  useEffect(() => {
    const duration = Math.trunc(Math.random() * Math.floor(1200)) + 100;

    const timeout = setTimeout(() => {
      if (index < messages.length - 1) {
        const percentageIncrease = Math.trunc(Math.random() * Math.floor(13)) + 12;

        addToProgress(percentageIncrease);
        setIndex((state) => (state + 1) % messages.length);
      } else {
        if (progress < 100) {
          addToProgress(100 - progress);
        }

        end();
      }
    }, duration);

    return () => clearTimeout(timeout);
  }, [addToProgress, end, index, messages.length, progress]);

  return (
    <Centered>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              {item.message}
            </animated.div>
          )
      )}
    </Centered>
  );
};

const Centered = styled.div`
  position: relative;
  width: 60%;
`;
