import React, { FC, useCallback, useState } from 'react';
import { useRouter } from '../../../routes';
import { getCached, setCached } from '../../../utils';

export interface EngineeringSidebarProps {
	readonly expanded?: boolean;
}

export const EngineeringSidebar: FC<EngineeringSidebarProps> = ({
	children,
	expanded: _expanded = false,
}) => {
	const [expanded, setExpanded] = useState(
		_expanded ||
			getCached('[@com.robhameetman]: Engineering Sidebar') === 'expanded',
	);
	const { params, replace } = useRouter();
	const { domain } = params;

	if (!domain) {
		replace('/engineering/languages-and-frameworks');
	}

	const handleClick = useCallback(() => {
		setExpanded(!expanded);
		setCached(
			'[@com.robhameetman]: Engineering Sidebar',
			expanded ? 'collapsed' : 'expanded',
		);
	}, [expanded]);

	return (
		<ul
			className={`inline-block bg-gray-100 dark:bg-app-gunmetal h-full trasition-width duration-500 ${
				expanded ? 'ease-out-elastic' : 'ease-in-elastic'
			} cursor-pointer ${expanded ? 'w-48' : 'w-10'}`}
			onClick={handleClick}
		>
			{children}
		</ul>
	);
};
