import React, { FC } from 'react';
import styled from 'styled-components';

export interface InstagramIconProps {}

export const InstagramIcon: FC<InstagramIconProps> = () => {
  return <Icon className="fab fa-instagram-square" />;
};

const Icon = styled.i``;
