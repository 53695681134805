import React, { FC } from 'react';
import { CrimsonTheme } from '@com.robhameetman/design';
import { Loading } from '../Loading';
import { Routes } from '../../routes';
import { AppProvider } from '../../global';

export interface AppProps {}

export const App: FC<AppProps> = () => {
	return (
		<React.StrictMode>
			<AppProvider theme={CrimsonTheme}>
				<Loading />
				<Routes />
			</AppProvider>
		</React.StrictMode>
	);
};
