export enum HttpRequestMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export type HttpRequestMethod = keyof typeof HttpRequestMethods;

export const isHttpRequestMethod = (value: unknown): value is HttpRequestMethod => {
  return typeof value === 'string'
    && Object.keys(HttpRequestMethods).includes(value);
};
