import React, { FC, useCallback, useLayoutEffect, useState } from 'react';
import { RhTheme } from '@com.robhameetman/design';
import { ThemeProvider } from 'styled-components';
import { AppContext } from '../AppContext';
import { getCached, getLocale, setCached } from '../../../utils';

const THEME_STORAGE_KEY = '[@com.robhameetman]: Theme';

export interface AppProviderProps {
	readonly theme?: RhTheme;
}

export enum Themes {
	light,
	dark,
}

export const AppProvider: FC<AppProviderProps> = ({ children, theme }) => {
	const [locale, setLocale] = useState(getLocale());
	const prefersDarkMode = true;

	const setTheme = useCallback(
		(theme = null) => setCached(THEME_STORAGE_KEY, theme),
		[],
	);

	const getTheme = () => getCached<Themes>(THEME_STORAGE_KEY);

	const [_theme, _setTheme] = useState(
		getTheme() !== null
			? getTheme()
			: window.matchMedia('(prefers-color-scheme: dark)').matches
			? Themes.dark
			: Themes.light,
	);

	const setDarkTheme = useCallback(() => {
		setTheme(Themes.dark);
		_setTheme(Themes.dark);
	}, [setTheme]);

	const setLightTheme = useCallback(() => {
		setTheme(Themes.light);
		_setTheme(Themes.light);
	}, [setTheme]);

	useLayoutEffect(() => {
		const $body = document.querySelector('body');

		const themeName =
			Themes[getTheme() ?? (prefersDarkMode ? Themes.dark : Themes.light)];

		/* eslint-disable-next-line */
		$body?.setAttribute('class', themeName);

		return () => $body?.removeAttribute('class');
	}, [prefersDarkMode, setTheme, _theme]);

	return (
		<AppContext.Provider
			value={{
				currentBreakpoint: 'sm',
				locale,
				setDarkTheme,
				setLightTheme,
				setLocale,
				theme: _theme,
			}}
		>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</AppContext.Provider>
	);
};
