import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface RecoveryIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const RecoveryIcon: FC<RecoveryIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 96.25 103.95"
			>
				<path
					d="M47.85,62.57h-.08a1.5,1.5,0,0,1-1.21-.74l-3.87-6.65a1.5,1.5,0,0,1,2.59-1.51L48,58.36,54.25,50a1.5,1.5,0,0,1,2.41,1.79L49.05,62A1.49,1.49,0,0,1,47.85,62.57Z"
					fill={primaryColor}
				/>
				<path
					d="M48,73.19a17.21,17.21,0,0,1,0-34.42h0a17.21,17.21,0,1,1,0,34.42Zm0-31.42A14.22,14.22,0,0,0,33.75,56a14.07,14.07,0,0,0,.88,4.89A14.21,14.21,0,1,0,48,41.77Z"
					fill={primaryColor}
				/>
				<path
					d="M80.92,90.43A1.49,1.49,0,0,1,79.86,90L70,80.1A1.54,1.54,0,0,1,69.52,79,1.47,1.47,0,0,1,70,78a31.06,31.06,0,0,0,.3-44,1.5,1.5,0,0,1,0-2.12L80.17,22a1.55,1.55,0,0,1,1.07-.44A1.5,1.5,0,0,1,82.3,22,48,48,0,0,1,82,90,1.52,1.52,0,0,1,80.92,90.43ZM73.13,79l7.78,7.78a45,45,0,0,0,.3-61.63L73.43,33A34.05,34.05,0,0,1,73.13,79Z"
					fill={secondaryColor}
				/>
				<path
					d="M15.33,90.43A1.52,1.52,0,0,1,14.28,90,48,48,0,0,1,14,22,1.5,1.5,0,0,1,15,21.53a1.37,1.37,0,0,1,1.07.44L26,31.86A1.5,1.5,0,0,1,26,34a31.06,31.06,0,0,0,.3,44A1.47,1.47,0,0,1,26.73,79a1.54,1.54,0,0,1-.44,1.07L16.39,90A1.49,1.49,0,0,1,15.33,90.43ZM15,25.17a45,45,0,0,0,.31,61.63L23.12,79A34.05,34.05,0,0,1,22.82,33Z"
					fill={secondaryColor}
				/>
				<path
					d="M71.34,34.43h0A1.55,1.55,0,0,1,70.27,34,31.06,31.06,0,0,0,26,34a1.55,1.55,0,0,1-1.06.45A1.58,1.58,0,0,1,23.85,34L14,24.09A1.5,1.5,0,0,1,14,22,48.06,48.06,0,0,1,82.3,22a1.5,1.5,0,0,1,0,2.12L72.4,34A1.5,1.5,0,0,1,71.34,34.43ZM48.12,21.7a33.84,33.84,0,0,1,23.2,9.12L79.1,23a45,45,0,0,0-61.95,0l7.78,7.78A33.83,33.83,0,0,1,48.12,21.7Z"
					fill={secondaryColor}
				/>
				<path
					d="M48.12,104A47.83,47.83,0,0,1,14.28,90a1.5,1.5,0,0,1,0-2.13l9.9-9.9a1.5,1.5,0,0,1,2.11,0A31.06,31.06,0,0,0,70,78a1.5,1.5,0,0,1,2.11,0l9.9,9.9A1.5,1.5,0,0,1,82,90,47.83,47.83,0,0,1,48.12,104Zm-30.64-15a45,45,0,0,0,61.29,0L71,81.13a34,34,0,0,1-45.73,0Z"
					fill={secondaryColor}
				/>
				<path
					d="M54.58,11.19a1.5,1.5,0,0,1-1.17-.56L48,3.86l-5.45,6.77a1.5,1.5,0,0,1-2.34-1.88L46.79.53a1.55,1.55,0,0,1,2.34,0l6.62,8.22a1.5,1.5,0,0,1-1.17,2.44Z"
					fill={secondaryColor}
				/>
			</svg>
		);
	},
);
