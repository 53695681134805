import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { useLoadingContext } from '../../hooks';

interface LoadingProgressProps {
	//
}

export const LoadingProgress: FC<LoadingProgressProps> = () => {
	const { progress } = useLoadingContext();
	const [feedback, setFeedback] = useState(`${progress}%`);
	const [indicatorOffset, setIndicatorOffset] = useState(0);

	const props = useSpring({
		from: {
			percent: 0,
		},
		to: {
			percent: progress,
		},
	});

	useEffect(() => {
		if (progress > 100) {
			setFeedback('100%');
		} else {
			setFeedback(`${progress}%`);
		}

		setIndicatorOffset(progress);
	}, [progress]);

	return (
		<animated.div style={props}>
			<ProgressBar>
				<Progress percent={progress} />
				<Indicator offset={indicatorOffset}>{feedback}</Indicator>
			</ProgressBar>
		</animated.div>
	);
};

interface ProgressBarProps {}

const ProgressBar = styled.div<ProgressBarProps>`
	position: relative;
	display: inline-flex;
	background-color: #2e3137;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	height: 2px;
	width: 100%;
`;

interface ProgressProps {
	percent: number;
}

const Progress = styled.div<ProgressProps>`
	position: absolute;
	background-color: #bc0031;
	width: ${({ percent }) => percent}%;
	height: 2px;
`;

interface IndicatorProps {
	offset: number;
}

const Indicator = styled.span<IndicatorProps>`
	position: absolute;
	left: 0;
	text-align: right;
	width: ${({ offset }) => offset}%;
	font-size: 0.7rem;
	font-weight: 600;
`;
