import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface ApiIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const ApiIcon: FC<ApiIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 104.33 103.39"
			>
				<path
					d="M102.83,103.39H1.5a1.5,1.5,0,0,1-1.5-1.5V16.32a1.5,1.5,0,0,1,1.5-1.5H102.83a1.5,1.5,0,0,1,1.5,1.5v85.57A1.5,1.5,0,0,1,102.83,103.39ZM3,100.39h98.33V17.82H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M102.83,17.82H1.5A1.5,1.5,0,0,1,0,16.32V1.5A1.5,1.5,0,0,1,1.5,0H102.83a1.5,1.5,0,0,1,1.5,1.5V16.32A1.5,1.5,0,0,1,102.83,17.82ZM3,14.82h98.33V3H3Z"
					fill={secondaryColor}
				/>
				<path
					d="M9.3,10.41a1.5,1.5,0,0,1-1.5-1.5,1.36,1.36,0,0,1,0-.29,1.31,1.31,0,0,1,.09-.28,1.26,1.26,0,0,1,.13-.26,2.11,2.11,0,0,1,.19-.23,1.55,1.55,0,0,1,2.12,0,2.11,2.11,0,0,1,.19.23,2.15,2.15,0,0,1,.14.26,2.29,2.29,0,0,1,.08.28,1.36,1.36,0,0,1,0,.29,1.5,1.5,0,0,1-1.5,1.5Z"
					fill={secondaryColor}
				/>
				<path
					d="M20.05,8.91a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,20.05,8.91Z"
					fill={secondaryColor}
				/>
				<path
					d="M33.81,10.41l-.3,0-.28-.08L33,10.16a1.37,1.37,0,0,1-.22-.19,1.5,1.5,0,0,1-.44-1.06,1.52,1.52,0,0,1,.44-1.06A1.37,1.37,0,0,1,33,7.66a1.26,1.26,0,0,1,.26-.13,1.5,1.5,0,0,1,2.08,1.38A1.5,1.5,0,0,1,34.87,10,1.52,1.52,0,0,1,33.81,10.41Z"
					fill={secondaryColor}
				/>
				<path
					d="M36.28,41.68H21.78a1.5,1.5,0,0,1-1.5-1.5V25.68a1.5,1.5,0,0,1,1.5-1.5h14.5a1.5,1.5,0,0,1,1.5,1.5v14.5A1.5,1.5,0,0,1,36.28,41.68Zm-13-3h11.5V27.18H23.28Z"
					fill={secondaryColor}
				/>
				<path
					d="M59.42,41.68a1.41,1.41,0,0,1-.58-.12.94.94,0,0,1-.26-.14,1.3,1.3,0,0,1-.23-.18,1.56,1.56,0,0,1-.44-1.06,2.58,2.58,0,0,1,0-.29A1.61,1.61,0,0,1,58,39.6a1.23,1.23,0,0,1,.14-.25,1.41,1.41,0,0,1,.18-.23,1.37,1.37,0,0,1,.23-.19l.26-.14.28-.08a1.5,1.5,0,0,1,1.35.41,1.06,1.06,0,0,1,.19.23,1.23,1.23,0,0,1,.14.25,1.61,1.61,0,0,1,.09.29,2.64,2.64,0,0,1,0,.29,1.52,1.52,0,0,1-.44,1.06A1.48,1.48,0,0,1,59.42,41.68Z"
					fill={secondaryColor}
				/>
				<path
					d="M50.67,40.18a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,50.67,40.18Z"
					fill={secondaryColor}
				/>
				<path
					d="M44.92,41.68a1.5,1.5,0,0,1-1.06-.44,1.5,1.5,0,0,1-.41-1.36c0-.09,0-.19.08-.28a2,2,0,0,1,.14-.25,1.06,1.06,0,0,1,.19-.23,1.49,1.49,0,0,1,1.35-.41l.28.08.26.14a2.11,2.11,0,0,1,.23.19,1,1,0,0,1,.18.23,1.23,1.23,0,0,1,.14.25A1.49,1.49,0,0,1,46,41.24a1.65,1.65,0,0,1-.77.41Z"
					fill={secondaryColor}
				/>
				<path
					d="M43.42,32.93a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,43.42,32.93Z"
					fill={secondaryColor}
				/>
				<path
					d="M44.92,27.18a1.5,1.5,0,0,1-1.06-.44,1.06,1.06,0,0,1-.19-.23,2.15,2.15,0,0,1-.14-.26,2.29,2.29,0,0,1-.08-.28,1.36,1.36,0,0,1,0-.29,1.5,1.5,0,0,1,1.79-1.47,1.31,1.31,0,0,1,.28.09,1.26,1.26,0,0,1,.26.13,2.11,2.11,0,0,1,.23.19,1.5,1.5,0,0,1,.44,1.06,1.36,1.36,0,0,1,0,.29,1.61,1.61,0,0,1-.23.54,1,1,0,0,1-.18.23,2.11,2.11,0,0,1-.23.19l-.26.14-.28.08Z"
					fill={secondaryColor}
				/>
				<path
					d="M50.67,25.68a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,50.67,25.68Z"
					fill={secondaryColor}
				/>
				<path
					d="M59.42,27.18a1.41,1.41,0,0,1-.58-.12,1.26,1.26,0,0,1-.26-.13,2.11,2.11,0,0,1-.23-.19,2,2,0,0,1-.18-.23,1.61,1.61,0,0,1-.23-.54,2.58,2.58,0,0,1,0-.29,1.54,1.54,0,0,1,.44-1.06,2.11,2.11,0,0,1,.23-.19l.26-.14.28-.08a1.5,1.5,0,0,1,1.79,1.47,2.64,2.64,0,0,1,0,.29,1.61,1.61,0,0,1-.23.54,1.37,1.37,0,0,1-.19.23A1.48,1.48,0,0,1,59.42,27.18Z"
					fill={secondaryColor}
				/>
				<path
					d="M57.91,32.93a1.5,1.5,0,0,1,1.5-1.5h0a1.51,1.51,0,0,1,1.51,1.5h0a1.51,1.51,0,0,1-1.51,1.5h0A1.5,1.5,0,0,1,57.91,32.93Z"
					fill={secondaryColor}
				/>
				<path
					d="M82.55,41.68H68.05a1.5,1.5,0,0,1-1.5-1.5V25.68a1.5,1.5,0,0,1,1.5-1.5h14.5a1.5,1.5,0,0,1,1.5,1.5v14.5A1.5,1.5,0,0,1,82.55,41.68Zm-13-3h11.5V27.18H69.55Z"
					fill={primaryColor}
				/>
				<path
					d="M36.28,65.87a1.5,1.5,0,0,1-1.5-1.5,1.43,1.43,0,0,1,0-.29,2.29,2.29,0,0,1,.08-.28,2.15,2.15,0,0,1,.14-.26,2.11,2.11,0,0,1,.19-.23,1.55,1.55,0,0,1,2.12,0,2.11,2.11,0,0,1,.19.23,1.26,1.26,0,0,1,.13.26,1.31,1.31,0,0,1,.09.28,1.43,1.43,0,0,1,0,.29,1.5,1.5,0,0,1-1.5,1.5Z"
					fill={secondaryColor}
				/>
				<path
					d="M27.53,64.37a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,27.53,64.37Z"
					fill={secondaryColor}
				/>
				<path
					d="M21.78,65.87a1.36,1.36,0,0,1-.29,0l-.28-.08L21,65.62a2.11,2.11,0,0,1-.23-.19,1.52,1.52,0,0,1-.44-1.06,1.43,1.43,0,0,1,0-.29,1.31,1.31,0,0,1,.09-.28,1.26,1.26,0,0,1,.13-.26,2.11,2.11,0,0,1,.19-.23,1.45,1.45,0,0,1,.23-.19,1.26,1.26,0,0,1,.26-.13,1.31,1.31,0,0,1,.28-.09,1.5,1.5,0,1,1,.29,3Z"
					fill={secondaryColor}
				/>
				<path
					d="M20.28,57.12a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,20.28,57.12Z"
					fill={secondaryColor}
				/>
				<path
					d="M21.78,51.37a1.36,1.36,0,0,1-.29,0,.9.9,0,0,1-.28-.08L21,51.12a1.37,1.37,0,0,1-.23-.19,1.48,1.48,0,0,1-.41-1.35,1.31,1.31,0,0,1,.09-.28,1.26,1.26,0,0,1,.13-.26,2.11,2.11,0,0,1,.19-.23,1.65,1.65,0,0,1,.77-.41,1.5,1.5,0,1,1,.29,3Z"
					fill={secondaryColor}
				/>
				<path
					d="M27.53,49.87a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,27.53,49.87Z"
					fill={secondaryColor}
				/>
				<path
					d="M36.28,51.37a1.5,1.5,0,0,1-1.06-.44,1.37,1.37,0,0,1-.19-.22c-.05-.09-.1-.17-.14-.26s-.06-.19-.08-.28a1.5,1.5,0,0,1,.41-1.36,1.55,1.55,0,0,1,2.12,0,1.47,1.47,0,0,1,.32,1.64,1.58,1.58,0,0,1-.13.26,1.37,1.37,0,0,1-.19.22A1.52,1.52,0,0,1,36.28,51.37Z"
					fill={secondaryColor}
				/>
				<path
					d="M34.78,57.12a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,34.78,57.12Z"
					fill={secondaryColor}
				/>
				<path
					d="M59.41,65.87H44.91a1.5,1.5,0,0,1-1.5-1.5V49.87a1.5,1.5,0,0,1,1.5-1.5h14.5a1.5,1.5,0,0,1,1.5,1.5v14.5A1.5,1.5,0,0,1,59.41,65.87Zm-13-3h11.5V51.37H46.41Z"
					fill={primaryColor}
				/>
				<path
					d="M82.55,65.87H68.05a1.5,1.5,0,0,1-1.5-1.5V49.87a1.5,1.5,0,0,1,1.5-1.5h14.5a1.5,1.5,0,0,1,1.5,1.5v14.5A1.5,1.5,0,0,1,82.55,65.87Zm-13-3h11.5V51.37H69.55Z"
					fill={secondaryColor}
				/>
				<path
					d="M36.28,90.06H21.78a1.5,1.5,0,0,1-1.5-1.5V74.06a1.5,1.5,0,0,1,1.5-1.5h14.5a1.5,1.5,0,0,1,1.5,1.5v14.5A1.5,1.5,0,0,1,36.28,90.06Zm-13-3h11.5V75.56H23.28Z"
					fill={primaryColor}
				/>
				<path
					d="M59.41,90.06H44.91a1.5,1.5,0,0,1-1.5-1.5V74.06a1.5,1.5,0,0,1,1.5-1.5h14.5a1.5,1.5,0,0,1,1.5,1.5v14.5A1.5,1.5,0,0,1,59.41,90.06Zm-13-3h11.5V75.56H46.41Z"
					fill={secondaryColor}
				/>
				<path
					d="M82.55,90.06a1.49,1.49,0,0,1-1.5-1.5,1.52,1.52,0,0,1,.44-1.06,1.55,1.55,0,0,1,2.12,0,1.51,1.51,0,0,1,0,2.13A1.55,1.55,0,0,1,82.55,90.06Z"
					fill={primaryColor}
				/>
				<path
					d="M73.8,88.56a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,73.8,88.56Z"
					fill={primaryColor}
				/>
				<path
					d="M68.05,90.07a1.36,1.36,0,0,1-.29,0,1.61,1.61,0,0,1-.54-.23,1.76,1.76,0,0,1-.42-.41,1.26,1.26,0,0,1-.13-.26A1.49,1.49,0,0,1,67,87.5a1.65,1.65,0,0,1,.77-.41,1.5,1.5,0,0,1,1.76,1.77c0,.09,0,.19-.08.28a2.15,2.15,0,0,1-.14.26,1.37,1.37,0,0,1-.19.22A1.51,1.51,0,0,1,68.05,90.07Z"
					fill={primaryColor}
				/>
				<path
					d="M66.55,81.32a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.49h0A1.5,1.5,0,0,1,66.55,81.32Z"
					fill={primaryColor}
				/>
				<path
					d="M68.05,75.57a1.36,1.36,0,0,1-.29,0,1.69,1.69,0,0,1-.77-.41,2.11,2.11,0,0,1-.19-.23,1.26,1.26,0,0,1-.13-.26,1.41,1.41,0,0,1-.12-.57A1.48,1.48,0,0,1,67,73a1.37,1.37,0,0,1,.23-.19,1.61,1.61,0,0,1,.54-.23,1.53,1.53,0,0,1,1.35.42,1.48,1.48,0,0,1,.44,1.06,1.5,1.5,0,0,1,0,.29c0,.09,0,.19-.08.28a2.15,2.15,0,0,1-.14.26,2.11,2.11,0,0,1-.19.23A1.5,1.5,0,0,1,68.05,75.57Z"
					fill={primaryColor}
				/>
				<path
					d="M73.8,74.07a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,73.8,74.07Z"
					fill={primaryColor}
				/>
				<path
					d="M82.55,75.57a1.5,1.5,0,0,1-1.47-1.8c0-.09,0-.19.08-.28a2.15,2.15,0,0,1,.14-.26,1.37,1.37,0,0,1,.19-.22,1.55,1.55,0,0,1,2.12,0,1.48,1.48,0,0,1,.44,1.06,1.5,1.5,0,0,1-1.5,1.5Z"
					fill={primaryColor}
				/>
				<path
					d="M81.05,81.32a1.5,1.5,0,0,1,1.5-1.5h0a1.5,1.5,0,0,1,1.5,1.5h0a1.5,1.5,0,0,1-1.5,1.5h0A1.5,1.5,0,0,1,81.05,81.32Z"
					fill={primaryColor}
				/>
			</svg>
		);
	},
);
