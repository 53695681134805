import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface OnIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const OnIcon: FC<OnIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 53.88 82.41"
			>
				<path
					d="M26.94,82.41A16.74,16.74,0,0,1,10.22,65.69V63.6a1.5,1.5,0,0,1,1.5-1.5H42.16a1.5,1.5,0,0,1,1.5,1.5v2.09A16.74,16.74,0,0,1,26.94,82.41ZM13.22,65.1v.59a13.72,13.72,0,0,0,27.44,0V65.1Z"
					fill={secondaryColor}
				/>
				<path
					d="M31.35,65.1H22.63a1.49,1.49,0,0,1-1.48-1.26L14,20.27a1.5,1.5,0,1,1,3-.48L23.9,62.1h6.18L37,19.79a1.5,1.5,0,0,1,3,.48L32.83,63.84A1.49,1.49,0,0,1,31.35,65.1Z"
					fill={primaryColor}
				/>
				<path
					d="M34.65,23.57a3.91,3.91,0,0,1-3-1.49c-.45-.49-.55-.55-.82-.55s-.37.06-.83.55a3.78,3.78,0,0,1-6,0c-.46-.49-.55-.55-.83-.55s-.36.06-.82.55a3.79,3.79,0,0,1-6,0c-.45-.49-.55-.55-.82-.55a1.5,1.5,0,0,1,0-3,3.89,3.89,0,0,1,3,1.49c.45.49.55.55.82.55s.37-.06.82-.55a3.79,3.79,0,0,1,6,0c.46.49.55.55.82.55s.37-.06.83-.55a3.79,3.79,0,0,1,6,0c.46.49.55.55.82.55s.37-.06.83-.55a3.89,3.89,0,0,1,3-1.49,1.5,1.5,0,0,1,0,3c-.28,0-.37.06-.82.55A3.91,3.91,0,0,1,34.65,23.57Z"
					fill={primaryColor}
				/>
				<path
					d="M42.16,65.1H11.72a1.5,1.5,0,0,1-1.5-1.5V55A17.35,17.35,0,0,0,6.15,44.08a26.94,26.94,0,1,1,41.37.25,16.93,16.93,0,0,0-3.86,11V63.6A1.5,1.5,0,0,1,42.16,65.1Zm-28.94-3H40.66V55.29a19.94,19.94,0,0,1,4.57-12.9,23.93,23.93,0,1,0-36.77-.22A20.31,20.31,0,0,1,13.22,55Z"
					fill={secondaryColor}
				/>
			</svg>
		);
	},
);
