import React, { FC } from 'react';
import styled from 'styled-components';

export interface GithubIconProps {}

export const GithubIcon: FC<GithubIconProps> = () => {
  return <Icon className="fab fa-github-square" />;
};

const Icon = styled.i``;
