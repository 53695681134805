import React, { FC } from 'react';
import { View } from '../../../lib';

export interface ProjectDetailsViewProps {
	/* TODO */
}

export const ProjectDetailsView: FC<ProjectDetailsViewProps> = () => {
	return <View>{/* TODO */}</View>;
};
