import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import Typing from 'react-typist';
import { Cursor } from '../../components';
import { useViewport } from '../../../../hooks';
import { useLocale } from '../../../../../global';
import {
	EmailIcon,
	GithubIcon,
	InstagramIcon,
	LinkedinIcon,
	PlanarVolumetricTranslation,
} from '../../../../../lib';

import strings from './Title.strings.json';

export interface TitleSectionProps {
	readonly hideContent?: boolean;
}

export const TitleSection: FC<TitleSectionProps> = ({
	hideContent = false,
}) => {
	const [npmCommandHidden, setNpmCommandHidden] = useState(false);
	const { width } = useViewport();
	const { locale } = useLocale();

	const handleTypingDone = useCallback(() => {
		setNpmCommandHidden(true);
		setTimeout(() => setNpmCommandHidden(false), 1000);
	}, []);

	const background = useMemo(
		() => (
			<Background w={width}>
				<PlanarVolumetricTranslation />
			</Background>
		),
		[width],
	);

	const npmCommandTyped = useMemo(() => {
		return npmCommandHidden ? (
			<>
				>&nbsp;
				<Cursor />
			</>
		) : (
			<>
				>&nbsp;
				<InlineTyping onTypingDone={handleTypingDone} cursor={{ element: '_' }}>
					<Typing.Delay ms={500} />
					npm init @rob.hameetman/hire
					<Typing.Delay ms={4500} />
				</InlineTyping>
			</>
		);
	}, [npmCommandHidden, handleTypingDone]);

	return (
		<Section>
			{background}
			<Content>
				{!hideContent && (
					<>
						<Title>
							<Selectable>
								{(strings.part1 as Record<string, string>)[locale]}
								<br />& {(strings.part2 as Record<string, string>)[locale]}:
							</Selectable>
						</Title>
						<Subtitle>{npmCommandTyped}</Subtitle>
						<Icons>
							<a href="https://www.github.com/RobHameetman">
								<GithubIcon />
							</a>
							<a href="https://www.linkedin.com/in/rhameetman/">
								<LinkedinIcon />
							</a>
							<a href="mailto:employment@robhameetman.com?subject=Interview%20Request&body=Hey%20Rob%2C%0A%0AIt%27s%20%7B%7BNAME%7D%7D%20over%20at%20%7B%7BCOMPANY%7D%7D.%20I%27d%20love%20to%20chat%20more%20about%20a%20%7B%7BTITLE%7D%7D%20role%20that%20we%20think%20you%27d%20be%20a%20great%20fit%20for%21%20The%20pay%20starts%20at%20%7B%7BSALARY%7D%7D%20annually.%20Please%20call%20%7B%7BPHONE%7D%7D%20or%20email%20%7B%7BEMAIL%7D%7D%20as%20soon%20as%20you%20have%20a%20chance.%20Looking%20forward%20to%20hearing%20from%20you%21">
								<EmailIcon />
							</a>
							<a href="https://www.instagram.com/robhameetman/">
								<InstagramIcon />
							</a>
						</Icons>
					</>
				)}
			</Content>
		</Section>
	);
};

interface BackgroundProps {
	w: number;
}

const Background = styled.div<BackgroundProps>`
	width: ${({ w }) => w}px;
	/* max-height: calc(100vh - 5rem); */
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	pointer-events: auto;
`;

const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	height: 100%;
	min-height: 100vh;
	width: 100%;
	z-index: 1;
`;

const Section = styled.section`
	align-items: center;
	display: flex;
	flex-grow: 1;
	justify-content: center;
	position: relative;
	font-size: 2rem;
	width: 100%;
	overflow: scroll;
	pointer-events: none;

	@media (min-width: 481px) {
		font-size: 3rem;
	}

	@media (min-width: 769px) {
		font-size: 4rem;
	}

	@media (min-width: 1081px) {
		font-size: 6rem;
	}
`;

const Title = styled.div`
	position: relative;
	color: ${({ theme }) => theme.appSecondaryColor};
	top: -2.5rem;
	left: 0;
	right: 0;
	text-align: center;
	line-height: 1.1;
	font-size: 1em;
	font-weight: 100;
	padding: 0.25em 0.25em 0 0.25em;
`;

const Selectable = styled.span`
	pointer-events: auto;
	overflow: visible;
`;

const Subtitle = styled.div`
	color: ${({ theme }) => theme.appSecondaryColor};
	font-size: 0.35em;
	line-height: 0.25em;
	height: 1.2em;
	font-weight: 700;
	text-align: center;
	pointer-events: auto;
	margin: 0.2rem;

	@media (max-width: 480px) {
		top: -2.5em;
	}
`;

const InlineTyping = styled(Typing)`
	display: inline-flex;
	${({ theme }) => theme.appSecondaryColor}
	height: 1.2em;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
`;

const Icons = styled.div`
	& {
		display: flex;
		color: ${({ theme }) => theme.appSecondaryColor};
		font-size: 0.35em !important;
		line-height: 0.25em;
		height: 1.2em;
		font-weight: 700;
		align-items: center;
		justify-content: center;
		margin-top: 2em;
		pointer-events: auto;

		@media (min-width: 1081px) {
			font-size: 1em;
		}
	}

	> * {
		display: inline-flex;
		color: ${({ theme }) => theme.appSecondaryColor};
		margin: 0 0.25rem;
		text-align: center;
		text-decoration: none;
		pointer-events: auto;
		transition: font-size 0.2s, color 0.2s;

		@media (min-width: 481px) {
			margin: 0 0.3rem;
		}

		@media (min-width: 769px) {
			margin: 0 0.4rem;
		}

		@media (min-width: 1081px) {
			margin: 0 0.5rem;
		}

		&:hover {
			font-size: 130%;
			color: #FFFFFF};
		}
	}
`;
