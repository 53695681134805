import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface StopwatchIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const StopwatchIcon: FC<StopwatchIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 101.24 101.85"
			>
				<path
					d="M36.45,38.12a1.49,1.49,0,0,1-1.06-.44l-4-4a1.5,1.5,0,0,1,2.12-2.12l4,4a1.51,1.51,0,0,1,0,2.12A1.53,1.53,0,0,1,36.45,38.12Z"
					fill={primaryColor}
				/>
				<path
					d="M78.89,80.56a1.52,1.52,0,0,1-1.06-.43l-4-4A1.5,1.5,0,0,1,76,74l4,4a1.5,1.5,0,0,1,0,2.13A1.48,1.48,0,0,1,78.89,80.56Z"
					fill={primaryColor}
				/>
				<path
					d="M74.93,38.12a1.51,1.51,0,0,1-1.06-.44,1.49,1.49,0,0,1,0-2.12l4-4a1.51,1.51,0,0,1,2.12,0,1.49,1.49,0,0,1,0,2.12l-4,4A1.51,1.51,0,0,1,74.93,38.12Z"
					fill={primaryColor}
				/>
				<path
					d="M32.48,80.56a1.5,1.5,0,0,1-1.06-.43,1.52,1.52,0,0,1,0-2.13l4-4a1.49,1.49,0,0,1,2.12,0,1.51,1.51,0,0,1,0,2.12l-4,4A1.48,1.48,0,0,1,32.48,80.56Z"
					fill={primaryColor}
				/>
				<path
					d="M55.69,30.15a1.5,1.5,0,0,1-1.5-1.5V23a1.5,1.5,0,1,1,3,0v5.61A1.5,1.5,0,0,1,55.69,30.15Z"
					fill={primaryColor}
				/>
				<path
					d="M55.69,90.18a1.5,1.5,0,0,1-1.5-1.5V83.07a1.5,1.5,0,0,1,3,0v5.61A1.5,1.5,0,0,1,55.69,90.18Z"
					fill={primaryColor}
				/>
				<path
					d="M28.48,57.36H22.87a1.5,1.5,0,0,1,0-3h5.61a1.5,1.5,0,1,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M88.51,57.36H82.9a1.5,1.5,0,0,1,0-3h5.61a1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M7.37,26.11A3.94,3.94,0,0,1,4.57,25L1.16,21.54a3.94,3.94,0,0,1,0-5.59L16,1.16a3.94,3.94,0,0,1,5.59,0L25,4.57a4,4,0,0,1,0,5.59L10.16,25A3.94,3.94,0,0,1,7.37,26.11ZM18.74,3a.92.92,0,0,0-.67.28L3.28,18.07a1,1,0,0,0,0,1.35l3.41,3.41a1,1,0,0,0,1.35,0L22.83,8a1,1,0,0,0,0-1.35L19.42,3.28A.94.94,0,0,0,18.74,3Z"
					fill={secondaryColor}
				/>
				<path
					d="M21.43,28.38a1.5,1.5,0,0,1-1.06-.44l-7.66-7.66a1.51,1.51,0,0,1,0-2.12L18,12.88a1.51,1.51,0,0,1,2.12,0l7.69,7.69a1.49,1.49,0,0,1-.1,2.21c-1.07.89-2,1.75-2.9,2.64-.71.71-1.42,1.48-2.23,2.43a1.54,1.54,0,0,1-1.09.53Zm-5.54-9.16,5.47,5.47c.45-.5.89-1,1.32-1.39.59-.59,1.2-1.17,1.86-1.75l-5.49-5.49Z"
					fill={secondaryColor}
				/>
				<path
					d="M55.23,101.85A46,46,0,0,1,20.29,25.9c.87-1,1.63-1.84,2.39-2.6.95-.95,2-1.87,3.1-2.82h0a46,46,0,0,1,62,67.91A45.85,45.85,0,0,1,55.23,101.85ZM27.7,22.78c-1.07.89-2,1.75-2.9,2.64-.71.71-1.42,1.48-2.23,2.43a43,43,0,1,0,5.13-5.07Zm27.53,71h0a37.91,37.91,0,1,1,26.84-11.1A37.7,37.7,0,0,1,55.23,93.79Zm0-72.89A34.91,34.91,0,1,0,80,31.12,34.88,34.88,0,0,0,55.23,20.9Z"
					fill={secondaryColor}
				/>
				<path
					d="M55.54,62.27a6.67,6.67,0,1,1,4.72-11.38,6.67,6.67,0,0,1-4.72,11.38Zm0-10.33A3.67,3.67,0,1,0,58.13,53,3.69,3.69,0,0,0,55.54,51.94Z"
					fill={primaryColor}
				/>
				<path
					d="M55.07,56.74H36.49a1.5,1.5,0,0,1,0-3H55.07a1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
			</svg>
		);
	},
);
