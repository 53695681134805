import React, { FC, MouseEvent, useCallback } from 'react';
import { useRouter } from '../../../routes';
import { getCached } from '../../../utils';

export interface EngineeringSidebarItemProps {
	readonly disabled?: boolean;
	readonly domain: string;
	readonly selected?: boolean;
	readonly icon?: FC<Record<string, unknown>>;
}

export const EngineeringSidebarItem: FC<EngineeringSidebarItemProps> = ({
	children,
	disabled = false,
	domain: _domain,
	selected = false,
	icon: Icon,
}) => {
	const { params, replace } = useRouter();
	const { domain } = params;

	if (!domain) {
		replace('/engineering/languages-and-frameworks');
	}

	const handleClick = useCallback(
		(e: MouseEvent<HTMLLIElement>) => {
			e.preventDefault();

			replace(`/engineering/${_domain}`);
		},
		[_domain, replace],
	);

	return (
		<li
			className={`flex p-3 items-center justify-start ${
				selected
					? 'bg-app-primary text-gray-100'
					: 'transition-colors dark:text-gray-100 hover:bg-gray-500 dark:hover:bg-gray-100 hover:text-gray-100 dark:hover:text-gray-500'
			}`}
			onClick={handleClick}
		>
			{Icon && (
				<Icon
					className="h-4 min-w-min inline-flex"
					primaryColor={selected ? '#2B2E33' : undefined}
				/>
			)}
			<span
				className={`inline-flex ml-4 text-xs transition-opacity delay-500 ${
					getCached('[@com.robhameetman]: Engineering Sidebar')
						? 'opacity-100'
						: 'opacity-0 invisible'
				}`}
			>
				{children}
			</span>
		</li>
	);
};
