import React, { FC } from 'react';
import styled from 'styled-components';

export interface TwitterIconProps {}

export const TwitterIcon: FC<TwitterIconProps> = () => {
  return <Icon className="fab fa-twitter-square" />;
};

const Icon = styled.i``;
