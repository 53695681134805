import React, { FC, useMemo, useRef, MutableRefObject } from 'react';
import {
	CatmullRomCurve3,
	Vector3,
	Mesh,
	MeshDepthMaterial,
	ShaderMaterial,
	Color,
} from 'three';
import { extend, useFrame, useThree } from 'react-three-fiber';
// @ts-ignore
import lerp from 'lerp';
// @ts-ignore
import { MeshLine, MeshLineMaterial } from 'threejs-meshline';

export interface SparksProps {
	mouse?: MutableRefObject<Array<number>>;
	count?: number;
	primaryColor?: Color;
	otherColors?: Array<Color>;
	radius?: number;
}

interface FatlineProps {
	color?: Color;
	curve?: Array<Vector3>;
	speed?: number;
	width?: number;
}

const r = () => Math.max(0.2, Math.random());

extend({ MeshDepthMaterial, MeshLine, MeshLineMaterial });

export const Fatline: FC<FatlineProps> = ({ color, curve, speed, width }) => {
	const material = useRef<ShaderMaterial>();

	useFrame(() => (material!.current!.uniforms!.dashOffset.value -= speed || 0));

	return (
		<mesh>
			// @ts-ignore
			<meshLine attach="geometry" vertices={curve} />
			// @ts-ignore
			<meshLineMaterial
				attach="material"
				ref={material}
				depthTest={false}
				opacity={1}
				alphaTest={true}
				lineWidth={width}
				color={color}
				dashArray={0.005}
				dashRatio={Math.random() - 0.25}
			/>
		</mesh>
	);
};

export const Sparks: FC<SparksProps> = ({
	mouse,
	count,
	primaryColor = new Color('#FFFFFF'),
	otherColors = [],
	radius = 10,
}) => {
	const lines = useMemo(
		() =>
			new Array(count).fill(null).map((_, index) => {
				const pos = new Vector3(
					Math.sin(0) * radius * r(),
					Math.cos(0) * radius * r(),
					0,
				);

				const points = new Array(30).fill(null).map((_, index) => {
					const angle = (index / 30) * Math.PI * 2;
					return pos
						.add(
							new Vector3(
								Math.sin(angle) * radius * r(),
								Math.cos(angle) * radius * r(),
								0,
							),
						)
						.clone();
				});

				const curve = new CatmullRomCurve3(points).getPoints(1000);

				const shouldBeAlternativeColor =
					Math.round(10 * Math.random()) % 5 === 1;

				const color = shouldBeAlternativeColor
					? otherColors[
							Math.round(otherColors.length * Math.random()) %
								otherColors.length
					  ]
					: primaryColor;

				return {
					color,
					width: Math.max(0.2, (0.3 * index) / 100),
					speed: Math.max(0.000025, 0.00005 * Math.random()),
					curve,
				};
			}),
		[count, otherColors, primaryColor, radius],
	);

	const ref = useRef<Mesh>();
	const { size, viewport } = useThree();
	const aspect = size.width / viewport.width;

	useFrame(() => {
		if (ref.current) {
			ref.current.rotation.x = lerp(
				ref.current.rotation.x,
				0 + mouse!.current![1] / aspect / 200,
				0.1,
			);

			ref.current.rotation.y = lerp(
				ref.current.rotation.y,
				0 + mouse!.current![0] / aspect / 400,
				0.1,
			);
		}
	});

	return (
		<group ref={ref}>
			<group
				position={[-radius * 26, -radius * 8, -200]}
				scale={[2, 30, 2]}
			>
				{lines.map((props, index) => (
					<Fatline key={index} {...props} />
				))}
			</group>
		</group>
	);
};
