import { Hook } from '../..';

export interface Env {
  readonly [key: string]: string | undefined;
}

export const useEnv: Hook<{}, Env> = () => {
  return {
    API_ENDPOINT: ((process || {}).env || {})['API_ENDPOINT'] || '',
  };
};
