import React, { FC, memo } from 'react';
import { Crimson } from '@com.robhameetman/design';

export interface ProgrammingOnScreenIconProps {
	readonly className?: string;
	readonly primaryColor?: string;
	readonly secondaryColor?: string;
}

export const ProgrammingOnScreenIcon: FC<ProgrammingOnScreenIconProps> = memo(
	({
		className = '',
		primaryColor = Crimson[100],
		secondaryColor = 'currentColor',
	}) => {
		return (
			<svg
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 102.49 101.89"
			>
				<path
					d="M97.36,79.88H5.14A5.14,5.14,0,0,1,0,74.74V5.13A5.14,5.14,0,0,1,5.14,0H97.36a5.13,5.13,0,0,1,5.13,5.13V74.74A5.14,5.14,0,0,1,97.36,79.88ZM5.14,3A2.14,2.14,0,0,0,3,5.13V74.74a2.14,2.14,0,0,0,2.14,2.14H97.36a2.14,2.14,0,0,0,2.13-2.14V5.13A2.14,2.14,0,0,0,97.36,3ZM93.72,72.6H8.77a1.5,1.5,0,0,1-1.5-1.5V8.77a1.5,1.5,0,0,1,1.5-1.5h85a1.5,1.5,0,0,1,1.5,1.5V71.1A1.5,1.5,0,0,1,93.72,72.6Zm-83.45-3h82V10.27H10.27Z"
					fill={secondaryColor}
				/>
				<path
					d="M61.56,93.8H41a1.5,1.5,0,0,1-1.5-1.5V78.38a1.5,1.5,0,0,1,1.5-1.5H61.56a1.5,1.5,0,0,1,1.5,1.5V92.3A1.5,1.5,0,0,1,61.56,93.8Zm-19.06-3H60.06V79.88H42.5Z"
					fill={secondaryColor}
				/>
				<path
					d="M73.24,101.89H29.31a1.5,1.5,0,0,1-1.5-1.5A9.6,9.6,0,0,1,37.4,90.8H65.15a9.6,9.6,0,0,1,9.59,9.59A1.5,1.5,0,0,1,73.24,101.89ZM31,98.89H71.57a6.61,6.61,0,0,0-6.42-5.09H37.4A6.61,6.61,0,0,0,31,98.89Z"
					fill={secondaryColor}
				/>
				<path
					d="M86.53,28h-71a1.5,1.5,0,0,1-1.5-1.5v-9a1.5,1.5,0,0,1,1.5-1.5h71A1.51,1.51,0,0,1,88,17.5v9A1.5,1.5,0,0,1,86.53,28ZM17,25H85V19H17Z"
					fill={primaryColor}
				/>
				<path
					d="M86.53,63.5h-71A1.5,1.5,0,0,1,14,62V26.54a1.5,1.5,0,0,1,1.5-1.5h71a1.5,1.5,0,0,1,1.5,1.5V62A1.5,1.5,0,0,1,86.53,63.5ZM17,60.5H85V28H17Z"
					fill={primaryColor}
				/>
				<path
					d="M38.86,37.67H22.94a1.5,1.5,0,1,1,0-3H38.86a1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M78.37,37.67H71a1.5,1.5,0,0,1,0-3h7.39a1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M66.29,37.67H55.55a1.5,1.5,0,0,1,0-3H66.29a1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M50.43,37.67H44.34a1.5,1.5,0,0,1,0-3h6.09a1.5,1.5,0,0,1,0,3Z"
					fill={primaryColor}
				/>
				<path
					d="M38.79,50.12H22.87a1.5,1.5,0,1,1,0-3H38.79a1.5,1.5,0,0,1,0,3Z"
					fill={secondaryColor}
				/>
				<path
					d="M78.3,50.12h-18a1.5,1.5,0,0,1,0-3h18a1.5,1.5,0,0,1,0,3Z"
					fill={secondaryColor}
				/>
				<path
					d="M55.55,50.12H44.27a1.5,1.5,0,0,1,0-3H55.55a1.5,1.5,0,0,1,0,3Z"
					fill={secondaryColor}
				/>
			</svg>
		);
	},
);
