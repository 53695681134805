import React, { FC } from 'react';
import styled from 'styled-components';
import { withSections } from './hocs';
import { View } from '../../../lib';

export interface HomeViewProps {
	//
}

export const HomeView: FC<HomeViewProps> = () => {
	return (
		<View>
			<Main>
				<Main.Title />
				{/* <Main.Footer /> */}
				{/* <Main.Circles /> */}
			</Main>
		</View>
	);
};

const StyledMain = styled.main`
	display: flex;
	flex-wrap: wrap;
	background-color: ${({ theme }) => theme.appBgColor};
	align-items: center;
	justify-content: center;
	flex-direction: column;
	grid-column: span 12 / span 12;
	overflow: scroll;
`;

const Main = withSections(StyledMain);
